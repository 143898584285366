import React, { useEffect } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@mui/material";
import AddAgentForm from "./AddAgent";
import {
  deleteCompany,
  getCompanies,
  updateCompanyStatus,
} from "../../redux2/user/userSlice";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Edit from "./Edit";
import Loading from "../../components/Loading";
import {
  USER_ROLE_FINANCE,
  USER_ROLE_SUPERAGENT,
} from "../../common/constants";
import { Search } from "@mui/icons-material";

const Agent = ({ setAgents }) => {
  const { companies, isError, loading } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [companyList, setCompanyList] = React.useState([]);

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companies.length > 0) {
      setCompanyList(companies);
    }
  }, [companies]);

  const handleChange = (event, id) => {
    dispatch(
      updateCompanyStatus({
        id,
        status: event.target.checked ? "active" : "inactive",
      })
    );
  };

  if (![USER_ROLE_SUPERAGENT, USER_ROLE_FINANCE].includes(user?.role)) {
    window.location.href = "/";
  }

  if (loading) {
    return <Loading />;
  }

  if (!loading && companies.length > 0) {
    setAgents(companies);
  }

  const handleSearch = (e) => {
    const search = e.target.value;
    if (search) {
      const filteredData = companies.filter((company) => {
        return (
          company?.name.toLowerCase().includes(search.toLowerCase()) ||
          company?.user?.firstName.toLowerCase().includes(search.toLowerCase())
        );
      });
      setCompanyList(filteredData);
    } else {
      setCompanyList(companies);
    }
  };

  const isSuper = [USER_ROLE_SUPERAGENT].includes(user?.role);

  return (
    <div className="row mt-6">
      <div className="col-md-2">
        {isSuper && <AddAgentForm />}
        {/* <Edit /> */}
      </div>
      <div className="col-md-10 flex md:justify-end">
        <div className="table-search ">
          <div className="input-group search-area mb-xxl-0 mb-4 border-1">
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
              onChange={handleSearch}
            />
            <span className="input-group-text">
              <Search size={35} className="text-main" />
            </span>
          </div>
        </div>
      </div>
      <div className="table-responsive h-screen">
        <table
          className="display w-full dataTable table-responsive-lg"
          id="example5"
          role="grid"
          aria-describedby="example5_info"
        >
          <thead>
            <tr role="row">
              <th className="sorting_asc bg-none">
                <div className="form-check  style-1">
                  <input
                    type="checkbox"
                    onClick={() => {}}
                    className="form-check-input"
                    id="checkAll"
                    required=""
                  />
                </div>
              </th>
              <th className="sorting">Company Name</th>
              <th className="sorting">Agent Name</th>
              <th className="sorting">Email</th>
              <th className="sorting">Role</th>
              <th className="sorting">Contact</th>
              <th className="sorting">Status</th>
              <th className="sorting bg-none"></th>
              <th className="sorting bg-none"></th>
            </tr>
          </thead>
          {
            <tbody>
              {isError ? (
                <tr className="text-danger text-2xl">
                  <td>
                    <p>Error occurred</p>
                  </td>
                </tr>
              ) : (
                <>
                  {companyList?.map((company, index) => {
                    return (
                      <tr role="row" className="odd" key={index}>
                        <td className="sorting_1">
                          {/* <div className="form-check  style-1">
                            <input
                              type="checkbox"
                              onClick={() => {}}
                              className="form-check-input"
                              id="customCheckBox2"
                              required=""
                            />
                          </div> */}
                          <p>{index + 1}</p>
                        </td>
                        <td>
                          <div className="concierge-bx">
                            <div>
                              {/* <span className="text-primary">#AGT-00025</span> */}
                              <h4 className="mt-1">
                                <Link
                                  className="text-black"
                                  to={"./guest-detail"}
                                >
                                  {company?.name}
                                </Link>
                              </h4>
                              <span className="fs-14">
                                Join on{" "}
                                {moment(company?.user?.createdAt).format(
                                  "MMMM Do YYYY"
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="concierge-bx">
                            <div>
                              <h4 className="mt-1">
                                {company?.user?.firstName}{" "}
                                {company?.user?.lastName}
                              </h4>
                            </div>
                          </div>
                        </td>
                        <td>{company?.user?.email}</td>
                        {/* <td>Group 01</td> */}
                        <td>
                          <Badge variant="warning badge light ml-4">
                            {company?.user?.role}
                          </Badge>
                        </td>
                        <td>
                          <div className="text-nowrap">
                            <span className="text-black font-w500">
                              <i className="fas fa-phone-volume me-2 text-primary"></i>
                              {company?.user?.phone}
                            </span>
                          </div>
                        </td>
                        <td>
                          {company?.status === "active" ? (
                            <span className="text-success font-w600">
                              ACTIVE
                            </span>
                          ) : (
                            <span className="text-danger font-w600">
                              INACTIVE
                            </span>
                          )}{" "}
                        </td>
                        <td>
                          <Switch
                            style={{ color: "#FBA82C" }}
                            checked={
                              company?.status === "active" ? true : false
                            }
                            onChange={(e) => handleChange(e, company?._id)}
                          />
                        </td>
                        <td>
                          <DropdownBlog company={company} />
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          }
        </table>
        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
          <div className="dataTables_info">Showing 1 of 1 entries</div>
          <div
            className="dataTables_paginate paging_simple_numbers"
            id="example2_paginate"
          >
            <Link
              className="paginate_button previous disabled"
              to="/guest-list"
            >
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
            </Link>
            {/* <span>
              {paggination.map((number, i) => (
                <Link key={i} to="/guest-list">
                  {1}
                </Link>
              ))}
            </span> */}

            <Link className="paginate_button next" to="/guest-list">
              <i className="fa fa-angle-double-right" aria-hidden="true"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const DropdownBlog = ({ company }) => {
  const dispatch = useDispatch();
  const handleDelete = (company) => {
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure to delete this ${company?.name}.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(deleteCompany(company._id));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <>
      <Dropdown className="dropdown text-sans-serif">
        <Dropdown.Toggle
          variant=""
          className="btn btn-primary i-false tp-btn-light sharp"
          type="button"
          id="order-dropdown-0"
          data-toggle="dropdown"
          data-boundary="viewport"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-right border py-0"
          aria-labelledby="order-dropdown-0"
        >
          <div className="py-2">
            {/* <Link className="dropdown-item" to="/agent-trends">
              <i className="fa fa-chart-pie me-2"></i>
              Trends
            </Link> */}
            <Link className="dropdown-item" to={`/flight-list/${company?._id}`}>
              <i className="fas fa-plane me-2"></i>
              Flight
            </Link>
            <Link
              className="dropdown-item"
              to={`/agent-billing/${company?._id}`}
            >
              <i className="fas fa-money-bill me-2"></i>
              Billings
            </Link>
            <div className="dropdown-item" to="#">
              <Edit company={company} />
            </div>
            <btn
              className="dropdown-item hover:cursor-pointer"
              onClick={() => handleDelete(company)}
            >
              <i className="fas fa-trash-alt me-2"></i>
              Delete
            </btn>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default Agent;
