import { LoadingButton } from "@mui/lab";
import {
  Button,
  MenuItem,
  Pagination,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getBilling,
  createBilling,
  createBookingDeposit,
  getTotalPayableCredit,
} from "../../redux2/billing/billingSlice";
import {
  updateCreditLimit,
  updateServiceCharge,
} from "../../redux2/user/userSlice";
import { BillingSchema } from "./user.util";
import excelExport from "../../utils/excelExport";
import Loading from "../../components/Loading";
import {
  ONE_WEEK_CREDIT,
  TWO_WEEK_CREDIT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_FINANCE,
  USER_ROLE_SUPERAGENT,
  WALLET_MINIMUM_AMOUNT,
} from "../../common/constants";
import { toast } from "react-toastify";
import CreditSettlement from "./components/CreditSettlement";
import USDCreditSettlement from "./components/USDCreditSettlement";
import { formatNumber } from "../../utils/convertToNumber";
import EditBilling from "../../components/Modals/EditBilling";

const AgentBilling = () => {
  const [openAddWallet, setOpenAddWallet] = useState(false);
  const [openAddLimit, setOpenAddLimit] = useState(false);
  const [openBookingDeposit, setOpenBookingDeposit] = useState(false);
  const [openServiceCharge, setOpenServiceCharge] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { id } = useParams();

  const { billing, credit, isLoading } = useSelector((state) => state.billing);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (![USER_ROLE_SUPERAGENT, USER_ROLE_FINANCE].includes(user?.role)) {
      window.location.href = "/booking";
    }
  }, [user?.role]);

  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      query: {
        startDate: "",
        endDate: "",
        limit: 20,
        page: currentPage || 1,
      },
      id,
    };
    dispatch(getBilling(data));
  }, [currentPage, dispatch, id]);

  useEffect(() => {
    if (
      billing?.company &&
      (billing?.company?.creditType === ONE_WEEK_CREDIT ||
        billing?.company?.creditType === TWO_WEEK_CREDIT)
    ) {
      dispatch(getTotalPayableCredit(id));
    }
  }, [billing?.company, dispatch, id]);

  function handleBilling(values) {
    setOpenAddWallet(false);
    const data = {
      ...values,
      id,
    };
    dispatch(createBilling(data));
    // window.location.reload();
  }

  function handleCreditDeposit(values) {
    setOpenBookingDeposit(false);

    if (
      values.bank === "wallet" &&
      billing?.company?.totalCreditLimit - billing?.company?.creditLimit >
        billing?.company?.wallet - WALLET_MINIMUM_AMOUNT
    ) {
      toast.error("Insufficient Balance");
      return;
    }

    const data = {
      ...values,
      id,
    };

    dispatch(createBookingDeposit(data));
  }

  function handleLimit(values) {
    setOpenAddLimit(false);

    const data = {
      ...values,
      id,
    };

    dispatch(updateCreditLimit(data));
    // window.location.reload();
  }

  function handleServiceCharge(values) {
    setOpenServiceCharge(false);

    const data = {
      ...values,
      id,
    };

    dispatch(updateServiceCharge(data));
  }

  function handleExport() {
    let name =
      billing?.company?.name + "Billing" + moment().format("DD-MM-YYYY");

    const data = billing?.billing?.map((bill) => {
      return {
        "Transaction ID": bill.transaction || 0,
        Amount: bill.amount || bill?.deposit,
        "Payment Method": bill.bank,
        "Service Charge": bill.serviceCharge || 0,
        "Paid By": bill.paidBy,
        "Transaction Date": moment(billing.company.createdAt).format(
          "DD-MM-YYYY"
        ),
      };
    });
    excelExport(data, name);
  }

  const [pricingRules, setPricingRules] = useState([
    { minPrice: 0, maxPrice: 34999, defaultCharge: 11 },
    { minPrice: 35000, maxPrice: 49999, defaultCharge: 15 },
    { minPrice: 50000, maxPrice: 59999, defaultCharge: 18 },
    { minPrice: 60000, maxPrice: 69999, defaultCharge: 22 },
    { minPrice: 70000, maxPrice: 79999, defaultCharge: 25 },
    { minPrice: 80000, maxPrice: 89999, defaultCharge: 29 },
    { minPrice: 90000, maxPrice: 99999, defaultCharge: 33 },
    { minPrice: 100000, maxPrice: 500000, defaultCharge: 36 },
    // { minPrice: 200000, maxPrice: 500000, defaultCharge: 54 },
  ]);

  // const [businessPricingRules] = useState([
  //   { minPrice: 20000, maxPrice: 34999, defaultCharge: 14 },
  //   { minPrice: 35000, maxPrice: 49999, defaultCharge: 18 },
  //   { minPrice: 50000, maxPrice: 69999, defaultCharge: 21 },
  //   { minPrice: 70000, maxPrice: 89999, defaultCharge: 25 },
  //   { minPrice: 90000, maxPrice: 109999, defaultCharge: 28 },
  //   { minPrice: 110000, maxPrice: 129999, defaultCharge: 32 },
  //   { minPrice: 130000, maxPrice: 149999, defaultCharge: 35 },
  //   { minPrice: 150000, maxPrice: 199999, defaultCharge: 48 },
  //   { minPrice: 200000, maxPrice: 500000, defaultCharge: 57 },
  // ]);

  const columnsCount = 2;

  const initialValues = billing?.company?.serviceChargeInternational?.[0]
    ?.charge
    ? billing?.company?.serviceChargeInternational?.reduce(
        (acc, rule, index) => ({
          ...acc,
          [`minPrice_${index}`]: rule.minPrice,
          [`maxPrice_${index}`]: rule.maxPrice,
          [`charge_${index}`]:
            rule.charge !== undefined
              ? rule.charge
              : pricingRules?.[index].defaultCharge,
        }),
        {
          local: billing?.company?.serviceChargeLocal ?? 0,
          international: billing?.company?.serviceChargeInternational ?? 0,
          internationalBusiness:
            billing?.company?.serviceChargeInternationalBusiness ?? 0,
          serviceChargeLabor: billing?.company?.serviceChargeLabor ?? 0,
          serviceChargeOneWay: billing?.company?.serviceChargeOneWay ?? 0,
          exchangeLocalSC: billing?.company?.exchangeLocalSC ?? 0,
          exchangeInternationalSC:
            billing?.company?.exchangeInternationalSC ?? 0,
          isLabor: billing?.company?.isLabor ?? false,
          isOneWay: billing?.company?.isOneWay ?? false,
          NDCServiceCharges: billing?.company?.NDCServiceCharge,
        }
      )
    : pricingRules.reduce(
        (acc, rule, index) => ({
          ...acc,
          [`minPrice_${index}`]: rule.minPrice,
          [`maxPrice_${index}`]: rule.maxPrice,
          [`charge_${index}`]:
            rule.charge !== undefined
              ? rule.charge
              : pricingRules?.[index].defaultCharge,
        }),
        {
          local: billing?.company?.serviceChargeLocal ?? 0,
          international: billing?.company?.serviceChargeInternational ?? 0,
          internationalBusiness:
            billing?.company?.serviceChargeInternationalBusiness ?? 0,
          serviceChargeLabor: billing?.company?.serviceChargeLabor ?? 0,
          serviceChargeOneWay: billing?.company?.serviceChargeOneWay ?? 0,
          isLabor: billing?.company?.isLabor ?? false,
          isOneWay: billing?.company?.isOneWay ?? false,
          NDCServiceCharges: billing?.company?.NDCServiceCharge ?? [
            { carrier: "EK", oneWay: 0, round: 0 },
            { carrier: "KQ", oneWay: 800, round: 1500 },
            { carrier: "QR", oneWay: 300, round: 1100 },
            { carrier: "TK", oneWay: 1000, round: 2000 },
          ],
        }
      );

  // const businessInitialValues =
  //   billing?.company?.serviceChargeInternationalBusiness.reduce(
  //     (acc, rule, index) => ({
  //       ...acc,
  //       [`businessMinPrice_${index}`]: rule.minPrice,
  //       [`businessMaxPrice_${index}`]: rule.maxPrice,
  //       [`businessCharge_${index}`]:
  //         rule.charge !== undefined
  //           ? rule.charge
  //           : businessPricingRules?.[index].defaultCharge,
  //     }),
  //     {}
  //   );

  // const combinedInitialValues = {
  //   ...initialValues,
  //   ...businessInitialValues,
  // };

  const [NDCServiceCharges, setNDCServiceCharges] = useState([
    { carrier: "EK", oneWay: 0, round: 0 },
    { carrier: "KQ", oneWay: 800, round: 1500 },
    { carrier: "QR", oneWay: 300, round: 1100 },
    { carrier: "TK", oneWay: 1000, round: 2000 },
  ]);

  useEffect(() => {
    if (billing?.company?.NDCServiceCharge?.length > 0) {
      setNDCServiceCharges(billing?.company?.NDCServiceCharge);
    }
  }, [billing?.company?.NDCServiceCharge]);

  const handleChangeCharge = (index, event) => {
    const { name, value } = event.target;

    const newServiceCharges = NDCServiceCharges.map((charge, i) =>
      i === index ? { ...charge, [name]: +value } : charge
    );
    setNDCServiceCharges(newServiceCharges);
  };

  if (isLoading) {
    return <Loading />;
  }

  const statData = [
    {
      title: "Wallet Balance",
      data: formatNumber(`${billing?.company?.wallet.toFixed(2)}`) + " birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-wallet2"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "primary",
    },
    {
      title: "Available Credit",
      data:
        formatNumber(`${billing?.company?.creditLimit?.toFixed(2) || 0}`) +
        " birr",
      // data: "200,000 birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-credit-card-fill"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "warning",
    },
    {
      title: "Available USD Credit",
      data:
        formatNumber(`${billing?.company?.USDCreditLimit.toFixed(2) || 0}`) +
        " USD",
      // data: "500,000 birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    // {
    //   title: "Unpaid Amount",
    //   data:
    //     formatNumber(
    //       `${billing?.company?.totalCreditLimit}` -
    //         `${billing?.company?.creditLimit}`
    //     ) + " birr",
    //   // data: "350,000 birr",
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       data-name="Layer 1"
    //       viewBox="0 0 24 24"
    //       id="IconChangeColor"
    //       height="30"
    //       width="30"
    //     >
    //       <path
    //         d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm5.86-1.55h0L4.71,2.29A1,1,0,0,0,3.29,3.71L4.59,5H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H18.59l2.7,2.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm-.74,2.09,1.34,1.34A1,1,0,0,1,12,13a1,1,0,0,1-1-1A1,1,0,0,1,11.12,11.54ZM4,17a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H6.59l3.1,3.1A3,3,0,0,0,9,12a3,3,0,0,0,3,3,3,3,0,0,0,1.9-.69L16.59,17ZM20,5H12.66a1,1,0,0,0,0,2H20a1,1,0,0,1,1,1v7.34a1,1,0,1,0,2,0V8A3,3,0,0,0,20,5Zm-1,7a1,1,0,1,0-1,1A1,1,0,0,0,19,12Z"
    //         id="mainIconPathAttribute"
    //       ></path>
    //     </svg>
    //   ),
    //   color: "success",
    // },
  ];

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const isSuper = [USER_ROLE_SUPERAGENT].includes(user?.role);

  return (
    <div className="row lg:mx-24 md:mx-16">
      <h1 className="text-3xl py-3 pl-2 capitalize">
        {billing?.company?.name}
      </h1>
      <div className="row">
        {statData.map(({ icon, color, title, data }) => (
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6" key={title}>
            <div className="widget-stat card">
              <div className="card-body p-3">
                <div className="media ai-icon">
                  <span className={`me-3 bgl-${color} text-${color}`}>
                    {icon}
                  </span>
                  <div className="media-body">
                    <p className="mb-1">{title}</p>
                    <h4 className="mb-0">{data}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
        {isSuper && (
          <div className="flex flex-col md:flex-row mx-2 md:gap-4">
            <div className="inline-block">
              <div
                className="btn bg-main  text-white btn-sm mb-xxl-0 mb-4 hover:bg-main-800"
                onClick={() => setOpenAddWallet(true)}
              >
                + Add To Wallet
              </div>
            </div>
            <div className="inline-block">
              <div
                className="btn bg-main  text-white btn-sm mb-xxl-0 mb-4 hover:bg-main-800 "
                onClick={() => setOpenAddLimit(true)}
                // onClick={() => setOpenAddLimit(false)}
                disabled
              >
                Update Credit Limit
              </div>
            </div>
            <div className="inline-block">
              <div
                className="btn bg-main  text-white btn-sm mb-xxl-0 mb-4 hover:bg-main-800"
                onClick={() => setOpenServiceCharge(true)}
                disabled
              >
                Update Service Charge
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-row">
          {isSuper && (
            <Dropdown className="dropdown text-sans-serif mx-auto">
              <Dropdown.Toggle
                // id="dropdown"
                variant=""
                className="p-2 text-white border-main-100 mx-3 px-4 border-1"
                type="button"
                id="order-dropdown-0"
                data-toggle="dropdown"
                data-boundary="viewport"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="flex">
                  <h1 className="mx-2">Action</h1>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <circle fill="#000000" cx={5} cy={12} r={2} />
                        <circle fill="#000000" cx={12} cy={12} r={2} />
                        <circle fill="#000000" cx={19} cy={12} r={2} />
                      </g>
                    </svg>
                  </span>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-right border py-0"
                aria-labelledby="order-dropdown-0"
                style={{ minWidth: "20rem" }}
              >
                <div className="mt-2 px-4 mx-2 text-black font-medium text-lg">
                  {billing?.company?.creditType !== "none" && (
                    <div className="py-2 hover:text-main">
                      <CreditSettlement
                        billing={billing}
                        id={id}
                        credit={credit}
                      />
                    </div>
                  )}

                  <div className="py-2 hover:text-main">
                    <USDCreditSettlement billing={billing} id={id} />
                  </div>

                  <div className="py-2 hover:text-main">
                    {/* <CreditPayment company={company} /> */}
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <div className="mx-auto">
            <button
              className="btn bg-[#EA9C2A] hover:bg-800 text-white border-main-100 mb-xxl-0 mb-4 hover:bg-main-800"
              onClick={handleExport}
            >
              <i className="far fa-file-word me-2"></i>Generate Report
            </button>
          </div>
        </div>
      </div>
      {/* <div class="relative mb-10 px-4 py-2">
        <div class="absolute top-0 right-0 ">
          <Link
            to={"#"}
            className="btn bg-main hover:bg-800 text-white border-main-100 mb-xxl-0 mb-4"
          >
            <i className="far fa-file-word me-2"></i>Generate Report
          </Link>
        </div>
      </div> */}

      <h3 className="text-2xl font-black text-primary">Transaction</h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body " style={{ padding: "1.25rem" }}>
              <div className="table-responsive">
                <table className="table table-sm mb-0 table-responsive-lg ">
                  <thead>
                    <tr className="text-black">
                      <th></th>
                      <th className="align-middle">Status</th>
                      <th className="align-middle">Created At</th>
                      <th className="align-middle">Transaction ID</th>
                      <th className="align-middle">Ticket Number</th>
                      <th className="align-middle">Payment Method</th>
                      <th className="align-middle">Deposit Amount</th>
                      <th className="align-middle pr-7">Total Price</th>
                      <th className="align-middle pr-7">System Fare</th>
                      <th className="align-middle ">Sterling S.C</th>
                      <th className="align-middle ">NDC S.C</th>
                      <th className="align-middle ">System Fee</th>
                      <th className="align-middle ">Net Revenue</th>
                      <th className="align-middle ">Agent Fee</th>
                      <th className="align-middle ">Passenger Name</th>
                      <th className="align-middle ">From Ticket Number</th>
                      <th className="align-middle ">Ticket Issue Date</th>
                      <th className="align-middle ">Tax</th>
                      <th className="align-middle ">Penalty</th>
                      <th className="align-middle ">Fare Difference</th>
                      <th className="align-middle ">Reason</th>
                      <th className="align-middle ">Withdrawn By</th>
                      <th className="align-middle ">Cheque Number</th>
                      <th className="align-middle ">Paid By</th>
                      <th className="align-middle ">Approved By</th>
                      <th className="align-middle">Balance</th>
                      <th className="align-middle">Action</th>
                      <th className="no-sort" />
                    </tr>
                  </thead>
                  <tbody id="orders">
                    {billing?.billing?.length === 0 ? (
                      <tr>
                        <td colSpan={11}>
                          <Typography variant="h5">No Billing Found</Typography>
                        </td>
                      </tr>
                    ) : (
                      billing?.billing?.map((item, index) => (
                        <tr
                          className="btn-reveal-trigger hover:bg-slate-100"
                          key={index}
                        >
                          <td>
                            {
                              <p>
                                {index +
                                  1 +
                                  (currentPage - 1) *
                                    billing?.pagination?.limit}{" "}
                              </p>
                            }
                          </td>
                          <td className="py-2">
                            {item?.type === "refundDeposit" ? (
                              <span className="badge bg-red-600">
                                Refund Deposit
                              </span>
                            ) : item?.type === "voidDeposit" ? (
                              <span className="badge bg-blue-600">
                                Void Deposit
                              </span>
                            ) : item?.reason ? (
                              <span className="badge badge-info">
                                Credit Payment
                              </span>
                            ) : item?.deposit ? (
                              <span className="badge badge-dark">
                                Wallet Deposit
                              </span>
                            ) : item?.status === "refunded" ? (
                              <span className="badge badge-danger">
                                Refunded
                              </span>
                            ) : item?.status === "voided" ? (
                              <span className="badge badge-secondary">
                                Void
                              </span>
                            ) : item?.status === "void-late" ? (
                              <span className="badge badge-secondary">
                                Void-Late
                              </span>
                            ) : item?.status === "exchanged" ? (
                              <span className="badge bg-green-700">
                                Exchanged
                              </span>
                            ) : item?.type === "exchangeFee" ? (
                              <span className="badge bg-green-700">
                                Exchanged Fee
                              </span>
                            ) : (
                              <span className="badge badge-success">
                                Success
                                <span className="ms-1 fa fa-check" />
                              </span>
                            )}
                          </td>
                          <td className="py-2">
                            {moment(item.createdAt).format("MMMM Do YYYY")}
                          </td>

                          <td className="py-2">{item?.transaction}</td>
                          <td>
                            {item?.ticketNumber?.map((ticket, index) => (
                              <div className="flex flex-col" key={index}>
                                {ticket}
                              </div>
                            ))}
                          </td>
                          <td className="py-2">{item.bank}</td>
                          <td className="py-2">
                            {item.deposit && item?.deposit + " birr"}
                          </td>
                          <td className="py-2">
                            {formatNumber(
                              item.amount +
                                (item?.serviceCharge || 0) +
                                (item.NDCServiceCharge || 0) || 0
                            )}{" "}
                            birr
                          </td>
                          <td className="py-2">
                            {formatNumber(item.amount)} birr
                          </td>
                          <td className="py-2">
                            {formatNumber(item.serviceCharge || 0)} birr
                          </td>
                          <td className="py-2">
                            {formatNumber(item.NDCServiceCharge || 0)} birr
                          </td>
                          <td className="py-2">
                            {formatNumber(item.systemFee || 0)} birr
                          </td>
                          <td className="py-2">
                            {formatNumber(
                              item.serviceCharge +
                                (item.NDCServiceCharge || 0) -
                                item.systemFee || 0
                            )}{" "}
                            birr
                          </td>
                          <td className="py-2">
                            {formatNumber(item.commission || 0)} birr
                          </td>
                          <td className="py-2">
                            {item.booking?.passengers[0]
                              ? item.booking?.passengers[0]?.firstName +
                                " " +
                                item.booking?.passengers[0]?.lastName
                              : ""}
                          </td>
                          <td>
                            {item?.type === "exchangeFee"
                              ? item?.booking?.flight?.exTicketNumber?.map(
                                  (ticket, index) => (
                                    <div className="flex flex-col" key={index}>
                                      {ticket}
                                    </div>
                                  )
                                )
                              : item?.booking?.flight?.ticketNumber?.map(
                                  (ticket, index) => (
                                    <div className="flex flex-col" key={index}>
                                      {ticket}
                                    </div>
                                  )
                                )}
                          </td>
                          <td className="py-2">
                            {moment(item?.booking?.createdAt).format(
                              "MMMM Do YYYY"
                            )}
                          </td>
                          <td className="py-2">{item.tax || 0}</td>
                          <td className="py-2">{item.penalty || 0}</td>
                          <td className="py-2">
                            {item.exchangeDifference || 0}
                          </td>
                          <td className="py-2">{item.reason}</td>
                          <td className="py-2">{item.withdrawMethod}</td>
                          <td className="py-2">{item.chequeNumber}</td>
                          <td className="py-2">{item.paidBy}</td>
                          <td className="py-2">{item.approvedBy}</td>
                          <td className="py-2">
                            {item.balance <= 0
                              ? "-"
                              : formatNumber(item.balance.toFixed(2)) + " birr"}
                          </td>
                          <td className="py-2">
                            <Dropdown className="dropdown text-sans-serif">
                              <Dropdown.Toggle
                                // id="dropdown"
                                variant=""
                                className="text-primary hover:bg-main-200 i-false tp-btn-light"
                                type="button"
                                id="order-dropdown-0"
                                data-toggle="dropdown"
                                data-boundary="viewport"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x={0}
                                        y={0}
                                        width={24}
                                        height={24}
                                      />
                                      <circle
                                        fill="#000000"
                                        cx={5}
                                        cy={12}
                                        r={2}
                                      />
                                      <circle
                                        fill="#000000"
                                        cx={12}
                                        cy={12}
                                        r={2}
                                      />
                                      <circle
                                        fill="#000000"
                                        cx={19}
                                        cy={12}
                                        r={2}
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="dropdown-menu dropdown-menu-right border py-0"
                                aria-labelledby="order-dropdown-0"
                              >
                                <button className="dropdown-item">
                                  <EditBilling billing={item} />
                                </button>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <div className="flex items-center justify-center my-5">
                  <Pagination
                    count={Math.ceil(billing?.pagination?.total)}
                    page={currentPage}
                    onChange={handlePageChange}
                    siblingCount={4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        show={openAddWallet}
        onHide={setOpenAddWallet}
        size="md"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add To Wallet</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenAddWallet(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close text-gray"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    deposit: "",
                    transaction: "",
                    bank: "",
                  }}
                  validationSchema={BillingSchema}
                  onSubmit={handleBilling}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} className="flex items-end">
                      <div className="flex flex-col">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <TextField
                              fullWidth
                              id="bank"
                              name="bank"
                              size="small"
                              value={values.bank}
                              label="Bank"
                              onChange={handleChange}
                              select
                              error={touched.bank && Boolean(errors.bank)}
                              helperText={touched.bank && errors.bank}
                            >
                              <MenuItem value="cbe">CBE</MenuItem>
                              <MenuItem value="awash">Awash Bank</MenuItem>
                              <MenuItem value="abyssinia">
                                Abyssinia Bank
                              </MenuItem>
                              <MenuItem value="amhara">Amhara Bank</MenuItem>
                              <MenuItem value="bunna">Bunna Bank</MenuItem>
                              <MenuItem value="wegagen">Wegagen Bank</MenuItem>
                            </TextField>
                          </div>
                          <div className="col-md-12 mb-3">
                            <TextField
                              fullWidth
                              type="number"
                              id="deposit"
                              size="small"
                              variant="outlined"
                              name="deposit"
                              placeholder="Deposit Amount"
                              label="Deposit Amount"
                              value={values.deposit}
                              // disabled={checker?.data?.user?.firstName ?? false}
                              onChange={handleChange}
                              error={touched.deposit && Boolean(errors.deposit)}
                              helperText={touched.deposit && errors.deposit}
                            />
                          </div>
                          <div className="col-md-12 mb-6">
                            <TextField
                              fullWidth
                              id="transaction"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="transaction"
                              placeholder="Transaction ID"
                              label="Transaction ID"
                              value={values.transaction}
                              onChange={handleChange}
                              error={
                                touched.transaction &&
                                Boolean(errors.transaction)
                              }
                              helperText={
                                touched.transaction && errors.transaction
                              }
                            />
                          </div>
                          {/* <div className="col-lg-6 mb-8">
                            <TextField
                              fullWidth
                              id="amount"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="amount"
                              // disabled={checker?.data?.user?.phone ?? false}
                              placeholder="Amount"
                              label="Amount"
                              value={values.amount}
                              onChange={handleChange}
                              // error={touched.phone && Boolean(errors.phone)}
                              // helperText={touched.phone && errors.phone}
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       +251
                              //     </InputAdornment>
                              //   ),
                              // }}
                            />
                          </div> */}
                          {/* <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="walletBalance"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="walletBalance"
                              placeholder="Wallet Balance"
                              label="Wallet Balance"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.walletBalance}
                              onChange={handleChange}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div> */}
                          {/* <div className="col-md-6 mb-8">
                            <TextField
                              fullWidth
                              id="creditLimit"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="creditLimit"
                              placeholder="Credit Limit"
                              label="Credit Limit"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.walletBalance}
                              onChange={handleChange}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div> */}
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"
                          // loading={add?.loading}
                        >
                          Add
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
                {/* ) : (
                  <>Here</>
                )} */}
              </div>
            </div>
            <div className="modal-footer">
              {/* <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); swal("Employee Added!", "Employee has been successfully added!", "success") }}>Add</button> */}
              <Button onClick={() => setOpenAddWallet(false)} variant="danger">
                {" "}
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={openAddLimit}
        onHide={setOpenAddLimit}
        size="md"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Update Credit Limit</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenAddLimit(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <i className="flat-icon-cancel-12 close text-black"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    kachaCredit: billing?.company?.kachaCredit ?? false,
                    creditLimit: billing?.company?.totalCreditLimit ?? 0,
                    creditType: billing?.company?.creditType ?? "none",
                    USDCreditType: billing?.company?.USDCreditType ?? "none",
                    USDCreditLimit: billing?.company?.USDTotalCreditLimit ?? 0,
                  }}
                  onSubmit={handleLimit}
                  // validationSchema={EmployeeSchema}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="flex flex-col justify-center">
                        <div className="row">
                          <div className="mb-8">
                            <Switch
                              checked={values.kachaCredit}
                              onChange={handleChange}
                              name="kachaCredit"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                            <label htmlFor="kachaCredit" className="text-black">
                              Kacha Credit (
                              {values.kachaCredit ? "True" : "False"})
                            </label>
                          </div>
                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="creditType"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="creditType"
                              placeholder="Credit Type"
                              label="Credit Type"
                              select
                              value={values.creditType}
                              onChange={handleChange}
                              defaultValue="none"
                            >
                              <MenuItem value="none">none</MenuItem>
                              <MenuItem value="oneDay">One Day Credit</MenuItem>
                              <MenuItem value="oneWeek" disabled>
                                One Week Credit
                              </MenuItem>
                              <MenuItem value="twoWeek" disabled>
                                Two Week Credit
                              </MenuItem>
                              <MenuItem value="oneMonth" disabled>
                                One Month Credit
                              </MenuItem>
                            </TextField>
                          </div>

                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="creditLimit"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="creditLimit"
                              placeholder="Credit Limit"
                              label="Credit Limit"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.creditLimit}
                              onChange={handleChange}
                              required={values.creditType !== "none"}
                              disabled={values.creditType === "none"}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>

                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="USDCreditType"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="USDCreditType"
                              placeholder="USD Credit Type"
                              label="USD Credit Type"
                              select
                              value={values.USDCreditType}
                              onChange={handleChange}
                              defaultValue="none"
                            >
                              <MenuItem value="none">none</MenuItem>
                              <MenuItem value="oneDay">One Day Credit</MenuItem>
                              <MenuItem value="oneWeek" disabled>
                                One Week Credit
                              </MenuItem>
                              <MenuItem value="oneMonth" disabled>
                                One Month Credit
                              </MenuItem>
                            </TextField>
                          </div>

                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="USDCreditLimit"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="USDCreditLimit"
                              placeholder="USD Credit Limit"
                              label="USD Credit Limit"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.USDCreditLimit}
                              onChange={handleChange}
                              required={values.USDCreditType !== "none"}
                              disabled={values.USDCreditType === "none"}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"
                          // loading={add?.loading}
                        >
                          Update
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="modal-footer">
              <Button onClick={() => setOpenAddLimit(false)} variant="danger">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        show={openServiceCharge}
        onHide={setOpenServiceCharge}
        size="md"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Update Service Charge</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenServiceCharge(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <i className="flat-icon-cancel-12 close text-black"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    // console.log(values);
                    const transformedPricingRules = Array.from(
                      { length: pricingRules.length },
                      (_, index) => ({
                        minPrice: pricingRules[`${index}`].minPrice,
                        maxPrice: pricingRules[`${index}`].maxPrice,
                        charge: values[`charge_${index}`],
                      })
                    );

                    // const transformedBusinessPricingRules = Array.from(
                    //   { length: businessPricingRules.length },
                    //   (_, index) => ({
                    //     minPrice: values[`businessMinPrice_${index}`],
                    //     maxPrice: values[`businessMaxPrice_${index}`],
                    //     charge: values[`businessCharge_${index}`],
                    //   })
                    // );

                    if (
                      NDCServiceCharges.length === 0 ||
                      NDCServiceCharges.some(
                        (value, index, self) =>
                          self.findIndex((t) => t.carrier === value.carrier) !==
                          index
                      )
                    ) {
                      toast.error("Invalid / Duplicated NDC Service Charges");
                      return;
                    }

                    const data = {
                      ...values,
                      international: transformedPricingRules,
                      serviceChargeLabor: values.isLabor
                        ? values.serviceChargeLabor
                        : 0,
                      serviceChargeOneWay: values.isOneWay
                        ? values.serviceChargeOneWay
                        : 0,
                      exchangeLocalSC: values.exchangeLocalSC,
                      exchangeInternationalSC: values.exchangeInternationalSC,
                      NDCServiceCharge: NDCServiceCharges,
                      // internationalBusiness: ,
                    };
                    handleServiceCharge(data);
                  }}
                  // validationSchema={EmployeeSchema}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="flex flex-col justify-center">
                        <div className="row">
                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="local"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="local"
                              placeholder="Local Service Charge"
                              label="Local Service Charge (in Birr)"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.local}
                              onChange={handleChange}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>
                          <div>
                            <label htmlFor="">
                              International Economy Service Charge
                            </label>
                            <ul className="col-md-12 mb-4">
                              <div className="row">
                                {[...Array(columnsCount)].map((_, colIndex) => (
                                  <div key={colIndex} className="col-md-6">
                                    {pricingRules
                                      .slice(
                                        colIndex *
                                          Math.ceil(
                                            pricingRules.length / columnsCount
                                          ),
                                        (colIndex + 1) *
                                          Math.ceil(
                                            pricingRules.length / columnsCount
                                          )
                                      )
                                      .map((rule, rowIndex) => (
                                        <li
                                          key={rowIndex}
                                          className="col-md-12 mb-4"
                                        >
                                          {/* Use hidden fields to store minPrice and maxPrice */}
                                          <input
                                            type="hidden"
                                            name={`minPrice_${
                                              colIndex *
                                                Math.ceil(
                                                  pricingRules.length /
                                                    columnsCount
                                                ) +
                                              rowIndex
                                            }`}
                                            value={rule.minPrice}
                                          />
                                          <input
                                            type="hidden"
                                            name={`maxPrice_${
                                              colIndex *
                                                Math.ceil(
                                                  pricingRules.length /
                                                    columnsCount
                                                ) +
                                              rowIndex
                                            }`}
                                            value={rule.maxPrice}
                                          />

                                          <TextField
                                            type="number"
                                            size="small"
                                            variant="outlined"
                                            className="w-full"
                                            label={`Charge for ${rule.minPrice} - ${rule.maxPrice}`}
                                            value={
                                              values[
                                                `charge_${
                                                  colIndex *
                                                    Math.ceil(
                                                      pricingRules.length /
                                                        columnsCount
                                                    ) +
                                                  rowIndex
                                                }`
                                              ]
                                            }
                                            onChange={handleChange}
                                            name={`charge_${
                                              colIndex *
                                                Math.ceil(
                                                  pricingRules.length /
                                                    columnsCount
                                                ) +
                                              rowIndex
                                            }`}
                                          />
                                        </li>
                                      ))}
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div>

                          {/* <div className="mb-8">
                            <label htmlFor="">
                              International Business Service Charge
                            </label>
                            <ul className="col-md-12 mb-4">
                              <div className="row">
                                {[...Array(columnsCount)].map((_, colIndex) => (
                                  <div key={colIndex} className="col-md-6">
                                    {businessPricingRules
                                      .slice(
                                        colIndex *
                                          Math.ceil(
                                            businessPricingRules.length /
                                              columnsCount
                                          ),
                                        (colIndex + 1) *
                                          Math.ceil(
                                            businessPricingRules.length /
                                              columnsCount
                                          )
                                      )
                                      .map((rule, rowIndex) => (
                                        <li
                                          key={rowIndex}
                                          className="col-md-12 mb-4"
                                        >
                                          <input
                                            type="hidden"
                                            name={`businessMinPrice_${
                                              colIndex *
                                                Math.ceil(
                                                  businessPricingRules.length /
                                                    columnsCount
                                                ) +
                                              rowIndex
                                            }`}
                                            value={rule.minPrice}
                                          />
                                          <input
                                            type="hidden"
                                            name={`businessMaxPrice_${
                                              colIndex *
                                                Math.ceil(
                                                  businessPricingRules.length /
                                                    columnsCount
                                                ) +
                                              rowIndex
                                            }`}
                                            value={rule.maxPrice}
                                          />

                                          <TextField
                                            type="number"
                                            size="small"
                                            variant="outlined"
                                            className="w-full"
                                            label={`Charge for ${rule.minPrice} - ${rule.maxPrice}`}
                                            value={
                                              values[
                                                `businessCharge_${
                                                  colIndex *
                                                    Math.ceil(
                                                      businessPricingRules.length /
                                                        columnsCount
                                                    ) +
                                                  rowIndex
                                                }`
                                              ]
                                            }
                                            onChange={handleChange}
                                            name={`businessCharge_${
                                              colIndex *
                                                Math.ceil(
                                                  businessPricingRules.length /
                                                    columnsCount
                                                ) +
                                              rowIndex
                                            }`}
                                          />
                                        </li>
                                      ))}
                                  </div>
                                ))}
                              </div>
                            </ul>
                          </div> */}

                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="internationalBusiness"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="internationalBusiness"
                              placeholder="International Business Service Charge"
                              label="International Business Service Charge (in Dollars)"
                              value={values.internationalBusiness}
                              onChange={handleChange}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>

                          <div className="col-md-6">
                            <TextField
                              fullWidth
                              id="exchangeLocalSC"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="exchangeLocalSC"
                              placeholder="Exchange Local SC"
                              label="Exchange Local SC (in Birr)"
                              value={values.exchangeLocalSC}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="col-md-6">
                            <TextField
                              fullWidth
                              id="exchangeInternationalSC"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="exchangeInternationalSC"
                              placeholder="International Exchange SC"
                              label="International Exchange SC (in Birr)"
                              value={values.exchangeInternationalSC}
                              onChange={handleChange}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>

                          <div className="col-md-6 my-2">
                            <Switch
                              checked={values.isLabor}
                              onChange={handleChange}
                              name="isLabor"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                            <label htmlFor="isLabor" className="text-black">
                              Is Labor Active
                            </label>
                            <div className="my-2">
                              <TextField
                                fullWidth
                                id="serviceChargeLabor"
                                type="number"
                                size="small"
                                variant="outlined"
                                name="serviceChargeLabor"
                                placeholder=" Labor Service Charge"
                                label=" Labor Service Charge (in Dollars)"
                                value={
                                  values.isLabor ? values.serviceChargeLabor : 0
                                }
                                disabled={!values.isLabor}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mb-8 my-2">
                            <Switch
                              checked={values.isOneWay}
                              onChange={handleChange}
                              name="isOneWay"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                            <label htmlFor="isOneWay" className="text-black">
                              Is OneWay Active
                            </label>
                            <div className="my-2">
                              <TextField
                                fullWidth
                                id="serviceChargeOneWay"
                                type="number"
                                size="small"
                                variant="outlined"
                                name="serviceChargeOneWay"
                                placeholder="OneWay Service Charge"
                                label=" OneWay Service Charge (in Dollars)"
                                value={
                                  values.isOneWay
                                    ? values.serviceChargeOneWay
                                    : 0
                                }
                                disabled={!values.isOneWay}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <label htmlFor="" className="text-black">
                            NDC SC
                          </label>
                          {NDCServiceCharges?.map((charge, index) => (
                            <div className="flex flex-row col-md-4 mb-8 my-2 w-full gap-3">
                              <TextField
                                labelId="carrier"
                                label="Carrier"
                                value={charge.carrier}
                                // onChange={(e) => handleCarrierChange(index, e)}
                                size="small"
                                placeholder="Carrier"
                                fullWidth
                                variant="outlined"
                                required
                                // disabled
                              />

                              <TextField
                                label="One Way Charge"
                                value={charge.oneWay}
                                // onChange={handleChange}
                                onChange={(e) => handleChangeCharge(index, e)}
                                name="oneWay"
                                type="number"
                                size="small"
                                fullWidth
                                min={0}
                                required
                              />
                              <TextField
                                label="Round Trip Charge"
                                value={charge.round}
                                // onChange={handleChange}
                                onChange={(e) => handleChangeCharge(index, e)}
                                name="round"
                                type="number"
                                size="small"
                                fullWidth
                                min={0}
                                required
                              />
                            </div>
                          ))}
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"
                          // loading={add?.loading}
                        >
                          Update
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                onClick={() => setOpenServiceCharge(false)}
                variant="danger"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {/* <Modal
        className="modal fade"
        show={openBookingDeposit}
        onHide={setOpenBookingDeposit}
        size="md"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Manual Credit Payment</h4>

              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenBookingDeposit(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <h4 className="fs-18 mb-4">
                Outstanding credit payment amount:{" "}
                <b className="">
                  {convertNumbThousand(
                    billing?.company?.totalCreditLimit -
                      billing?.company?.creditLimit
                  )}
                </b>{" "}
                birr
              </h4>
              <i className="flat-icon-cancel-12 close text-black"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    transaction: "",
                    reason: "",
                    bank: "",
                  }}
                  onSubmit={handleCreditDeposit}
                  // validationSchema={EmployeeSchema}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="flex flex-col justify-center">
                        <div className="row">
                          <div className=" mb-3">
                            <TextField
                              fullWidth
                              id="bank"
                              name="bank"
                              size="small"
                              value={values.bank}
                              label="Bank"
                              onChange={handleChange}
                              select
                              required
                              // error={touched.bank && Boolean(errors.bank)}
                              // helperText={touched.bank && errors.bank}
                            >
                              <MenuItem value="cbe">CBE</MenuItem>
                              <MenuItem value="awash">Awash Bank</MenuItem>
                              <MenuItem value="abyssinia">
                                Abyssinia Bank
                              </MenuItem>
                              <MenuItem value="amhara">Amhara Bank</MenuItem>
                              <MenuItem value="wallet">Wallet</MenuItem>
                            </TextField>
                          </div>
                          <div className="mb-3">
                            <TextField
                              fullWidth
                              id="transaction"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="transaction"
                              placeholder="Transaction ID/ Payment Reference"
                              label="Transaction ID/ Payment Reference"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.transaction}
                              onChange={handleChange}
                              required={values.bank !== "wallet"}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>

                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="reason"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="reason"
                              placeholder="Reason"
                              label="Reason"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.reason}
                              onChange={handleChange}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"

                          // loading={add?.loading}
                        >
                          Credit Paid
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                onClick={() => setOpenBookingDeposit(false)}
                variant="danger"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal> */}
    </div>
  );
};

export default AgentBilling;
