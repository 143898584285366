import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import convertNumbThousand from "../../utils/convertNumbThousand";
import ButtonPrimary from "../Button/ButtonPrimary";
import {
  getCarrierInfo,
  getCarrierLogo,
  showUSDInstruction,
} from "../../utils/flightInfo";
import calculateTime from "../../utils/calculateTime";
import customTime from "../../utils/customTime";
import Locals from "../../utils/localFlights";
import { NON_DOCUMENT_HOLDER } from "../../common/constants";

const OneWay = ({
  className = "",
  flights,
  flightDetail,
  flight,
  charge,
  query,
  bookingRef,
  passengerTypeState,
  currencyStates,
  rateDifference,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  if (!flights && flight && flightDetail) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
      </div>
    );
  }

  const getCarrier = (segment) => {
    let code = flights.find(
      (flight) => flight._attributes.Key === segment._attributes.Key
    )._attributes.Carrier;
    return getCarrierInfo(code)?.Name || code;
    // return CarrierList.find((carrier) => carrier.Code === code)?.Name || code;
  };

  const getAirSegment = (segment) => {
    return flights.find(
      (flight) => flight._attributes.Key === segment._attributes.Key
    )._attributes;
  };

  const getLogo = (segment) => {
    let code = flights.find(
      (flight) => flight._attributes.Key === segment._attributes.Key
    )._attributes.Carrier;

    return getCarrierLogo(code);
  };

  const getCabinClass = (segment) => {
    let cabin = "";

    flight?.AirPricingInfo[0]?.BookingInfo
      ? (cabin = flight?.AirPricingInfo[0]?.BookingInfo?.find(
          (flight) => flight._attributes.SegmentRef === segment._attributes.Key
        )._attributes.CabinClass)
      : (cabin = flight?.AirPricingInfo?.BookingInfo?.find(
          (flight) => flight._attributes.SegmentRef === segment._attributes.Key
        )._attributes.CabinClass);

    return cabin;
  };

  const selectBtnLink = (segment) => {
    let data = [];
    if (segment instanceof Array) {
      segment.map((seg, index) => {
        data.push({
          ...getAirSegment(seg),
          classType:
            flight?.AirPricingInfo?.BookingInfo?.[index]?._attributes
              ?.BookingCode ??
            flight?.AirPricingInfo[0]?.BookingInfo?.[index]?._attributes
              ?.BookingCode,
        });
      });
    } else {
      // data.push(getAirSegment(segment));
      // flight?.AirPricingInfo instanceof Array
      //   ? data.push({
      //       ...getAirSegment(segment),
      //       classType:
      //         flight?.AirPricingInfo[0]?.BookingInfo?._attributes?.BookingCode,
      //     })
      //   :
      data.push({
        ...getAirSegment(segment),
        classType:
          flight?.AirPricingInfo?.BookingInfo?._attributes?.BookingCode ??
          flight?.AirPricingInfo[0]?.BookingInfo?._attributes?.BookingCode,
      });
    }

    navigate(`/flight-detail2?${query}`, {
      state: {
        segmentData: data,
        charges: charge,
        queryURL: query,
        bookingRef: bookingRef,
        segmentConnection: flight.Connection,
        currency: currencyStates,
        rateDifference,
        // classType: flight?.AirPricingInfo?.BookingInfo,
      },
    });
  };

  function totalPrice(price) {
    const airPrice = parseFloat(price?.toString().slice(3));

    let result =
      rateDifference > 1 ? (airPrice * rateDifference).toFixed(2) : airPrice;

    result = parseFloat(result) + charge;
    return convertNumbThousand(result);
  }

  function disableFlight(carrier) {
    let origin = getAirSegment(
      flight.Journey.AirSegmentRef?.[0] || flight.Journey.AirSegmentRef
    )?.Origin;

    if (
      !Locals.includes(origin) &&
      (carrier === "TK" ||
        carrier === "EK" ||
        carrier === "QR" ||
        carrier === "MS")
    ) {
      return true;
    }

    if (
      !Locals.includes(origin) &&
      currencyStates !== "USD" &&
      (carrier === "SV" || carrier === "KQ")
    ) {
      return true;
    }

    if (
      carrier === "ET" &&
      currencyStates === "USD" &&
      passengerTypeState !== NON_DOCUMENT_HOLDER
    ) {
      return true;
    }

    if (carrier === "XY" || carrier === "GF") {
      return true;
    }

    if (
      passengerTypeState === NON_DOCUMENT_HOLDER &&
      // carrier !== "ET" &&
      currencyStates !== "USD"
    ) {
      return true;
    }

    return false;
  }

  const renderDetailTop = (seg, stat, btnVisible, index) => {
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img src={getLogo(seg)} className="w-15" alt="" />
          </div>
          <div className="flex my-5 md:my-0 lg:w-72">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              {stat ? (
                <>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      <>{customTime(getAirSegment(seg).DepartureTime)}</>
                    </span>
                    <span className=" font-semibold text-black">
                      {getAirSegment(seg).Origin}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {/* Monday, August 16 · 10:00 */}
                      {customTime(getAirSegment(seg).ArrivalTime)}
                    </span>
                    <span className=" font-semibold text-black">
                      {getAirSegment(seg).Destination}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {customTime(
                        getAirSegment(flight.Journey.AirSegmentRef)
                          .DepartureTime
                      )}
                    </span>
                    <span className=" font-semibold text-black">
                      {getAirSegment(flight.Journey.AirSegmentRef).Origin}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className=" text-neutral-500 dark:text-neutral-400">
                      {customTime(
                        getAirSegment(flight.Journey.AirSegmentRef).ArrivalTime
                      )}
                    </span>
                    <span className=" font-semibold text-black">
                      {getAirSegment(flight.Journey.AirSegmentRef).Destination}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:ml-10"></div>
          {stat ? (
            <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
              <li>
                Flight time:{" "}
                <span>
                  {calculateTime(
                    getAirSegment(seg).ArrivalTime,
                    getAirSegment(seg).DepartureTime
                  )}
                </span>
              </li>
              <li>Flight Class: {getCabinClass(seg)}</li>
              <li>Flight Number: {getAirSegment(seg).FlightNumber}</li>
              <li>Equipment: {getAirSegment(seg).Equipment}</li>
              <li>
                Booking Class:{" "}
                {flight?.AirPricingInfo?.[index]?.BookingInfo?.[index]
                  ?._attributes?.BookingCode ||
                  flight?.AirPricingInfo?.BookingInfo?.[index]?._attributes
                    ?.BookingCode}
              </li>
            </ul>
          ) : (
            <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
              <li>
                Trip time:{" "}
                <span>
                  {calculateTime(
                    getAirSegment(flight.Journey.AirSegmentRef).ArrivalTime,
                    getAirSegment(flight.Journey.AirSegmentRef).DepartureTime
                  )}
                </span>
              </li>
              <li>
                Flight Class:{" "}
                {flight?.AirPricingInfo?.[0]?.BookingInfo?._attributes
                  ?.CabinClass ||
                  flight?.AirPricingInfo?.BookingInfo?._attributes?.CabinClass}
              </li>
              <li>Flight Number: {getAirSegment(seg).FlightNumber}</li>
              <li>Equipment: {getAirSegment(seg).Equipment}</li>
              <li>
                Booking Class:{" "}
                {flight?.AirPricingInfo?.[0]?.BookingInfo?._attributes
                  ?.BookingCode ||
                  flight?.AirPricingInfo?.BookingInfo?._attributes?.BookingCode}
              </li>
            </ul>
          )}
          {stat ? (
            btnVisible && (
              <>
                <div className="mx-auto "></div>
                <div className="px-6 py-2 mt-auto flex flex-col">
                  <p className="text-xs text-red-600 p-2">
                    {showUSDInstruction(
                      getAirSegment(flight.Journey.AirSegmentRef?.[0])?.Carrier,
                      getAirSegment(flight.Journey.AirSegmentRef?.[0])?.Origin,
                      currencyStates,
                      passengerTypeState
                    )}
                  </p>
                  <ButtonPrimary
                    onClick={() => selectBtnLink(flight.Journey.AirSegmentRef)}
                    disabled={disableFlight(
                      getAirSegment(flight.Journey.AirSegmentRef?.[0])?.Carrier
                    )}
                    className={`${
                      disableFlight(
                        getAirSegment(flight.Journey.AirSegmentRef?.[0])
                          ?.Carrier
                      ) && "bg-gray-500 cursor-not-allowed hover:bg-gray-500"
                    }`}
                  >
                    Select
                  </ButtonPrimary>
                </div>
              </>
            )
          ) : (
            <>
              <div className="mx-auto"></div>
              <div className="px-6 py-2 mt-auto flex flex-col">
                <p className="text-xs text-red-600 p-2">
                  {showUSDInstruction(
                    getAirSegment(seg)?.Carrier,
                    getAirSegment(seg)?.Origin,
                    currencyStates,
                    passengerTypeState
                  )}
                </p>
                <ButtonPrimary
                  className={`${
                    disableFlight(
                      getAirSegment(flight.Journey.AirSegmentRef)?.Carrier
                    ) && "bg-gray-500 cursor-not-allowed hover:bg-gray-500"
                  }`}
                  onClick={() => selectBtnLink(seg)}
                  disabled={disableFlight(getAirSegment(seg)?.Carrier)}
                >
                  {" "}
                  Select
                </ButtonPrimary>
                {/* </Link> */}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    console.log(flight?.AirPricingInfo?.BookingInfo);
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {flight.Journey.AirSegmentRef instanceof Array ? (
          flight.Journey.AirSegmentRef.map((seg, index) => (
            <div key={index}>
              {renderDetailTop(
                seg,
                true,
                index === flight.Journey.AirSegmentRef.length - 1,
                index
              )}
              {index === flight.Journey.AirSegmentRef.length - 1 ? null : (
                <div className="my-7 md:my-10 space-y-5 md:pl-24">
                  <div className="border-t border-neutral-200 dark:border-neutral-700" />
                  <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
                    Transit time:{" "}
                    {calculateTime(
                      getAirSegment(flight.Journey.AirSegmentRef[index + 1])
                        .DepartureTime,
                      getAirSegment(flight.Journey.AirSegmentRef[index])
                        .ArrivalTime
                    )}
                  </div>
                  <div className="border-t border-neutral-200 dark:border-neutral-700" />
                </div>
              )}
            </div>
          ))
        ) : (
          <>{renderDetailTop(flight.Journey.AirSegmentRef, false)}</>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white border border-neutral-100 shadow
     rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
      onClick={() => {
        setIsOpen(!isOpen);
        localStorage.setItem("pairedRefs", JSON.stringify([]));
      }}
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          // onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">
            <img
              src={getLogo(
                flight.Journey.AirSegmentRef instanceof Array
                  ? flight.Journey.AirSegmentRef[0]
                  : flight.Journey.AirSegmentRef
              )}
              className="w-16"
              alt=""
            />
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                {flight.Journey.AirSegmentRef instanceof Array ? (
                  <span>
                    {customTime(
                      getAirSegment(flight.Journey.AirSegmentRef[0])
                        .DepartureTime
                    )}{" "}
                  </span>
                ) : (
                  <span>
                    {customTime(
                      getAirSegment(flight.Journey.AirSegmentRef).DepartureTime
                    )}
                  </span>
                )}
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {
                    getAirSegment(
                      flight.Journey.AirSegmentRef instanceof Array
                        ? flight.Journey.AirSegmentRef[0]
                        : flight.Journey.AirSegmentRef
                    ).Origin
                  }
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>
                  {flight.Journey.AirSegmentRef instanceof Array ? (
                    <span>
                      {customTime(
                        getAirSegment(flight.Journey.AirSegmentRef[0])
                          .ArrivalTime
                      )}{" "}
                    </span>
                  ) : (
                    <span>
                      {customTime(
                        getAirSegment(flight.Journey.AirSegmentRef).ArrivalTime
                      )}
                    </span>
                  )}
                </span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {
                    getAirSegment(
                      flight.Journey.AirSegmentRef instanceof Array
                        ? flight.Journey.AirSegmentRef[
                            flight.Journey.AirSegmentRef.length - 1
                          ]
                        : flight.Journey.AirSegmentRef
                    ).Destination
                  }
                </span>
              </div>
              <div className="w-24"></div>

              <div className="text-sm text-black font-normal mt-0.5 d-flex text-right">
                <span className="VG3hNb">
                  {flight.Journey.AirSegmentRef instanceof Array
                    ? flight.Journey.AirSegmentRef.length - 1 + " Stops"
                    : "Direct"}
                </span>
              </div>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg text-black">
              {flight.Journey.AirSegmentRef instanceof Array ? (
                <span>
                  {customTime(
                    getAirSegment(flight.Journey.AirSegmentRef[0]).DepartureTime
                  )}{" "}
                  -
                  {customTime(
                    getAirSegment(
                      flight.Journey.AirSegmentRef[
                        flight.Journey.AirSegmentRef.length - 1
                      ]
                    ).ArrivalTime
                  )}
                </span>
              ) : (
                customTime(
                  getAirSegment(flight.Journey.AirSegmentRef).DepartureTime
                ) +
                " - " +
                customTime(
                  getAirSegment(flight.Journey.AirSegmentRef).ArrivalTime
                )
              )}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* {data.airlines.name} */}
              {getCarrier(
                flight.Journey.AirSegmentRef instanceof Array
                  ? flight.Journey.AirSegmentRef[0]
                  : flight.Journey.AirSegmentRef
              )}
            </div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg text-black">
              {
                getAirSegment(
                  flight.Journey.AirSegmentRef instanceof Array
                    ? flight.Journey.AirSegmentRef[0]
                    : flight.Journey.AirSegmentRef
                ).Origin
              }{" "}
              -{" "}
              {
                getAirSegment(
                  flight.Journey.AirSegmentRef instanceof Array
                    ? flight.Journey.AirSegmentRef[
                        flight.Journey.AirSegmentRef.length - 1
                      ]
                    : flight.Journey.AirSegmentRef
                ).Destination
              }
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* 7 hours 15 minutes || Unknown yet */}
              {moment(
                getAirSegment(
                  flight.Journey.AirSegmentRef instanceof Array
                    ? flight.Journey.AirSegmentRef[
                        flight.Journey.AirSegmentRef.length - 1
                      ]
                    : flight.Journey.AirSegmentRef
                ).ArrivalTime
              ).diff(
                getAirSegment(
                  flight.Journey.AirSegmentRef instanceof Array
                    ? flight.Journey.AirSegmentRef[0]
                    : flight.Journey.AirSegmentRef
                ).DepartureTime,
                "hours"
              )}{" "}
              hours{" "}
              {moment(
                getAirSegment(
                  flight.Journey.AirSegmentRef instanceof Array
                    ? flight.Journey.AirSegmentRef[
                        flight.Journey.AirSegmentRef.length - 1
                      ]
                    : flight.Journey.AirSegmentRef
                ).ArrivalTime
              ).diff(
                getAirSegment(
                  flight.Journey.AirSegmentRef instanceof Array
                    ? flight.Journey.AirSegmentRef[0]
                    : flight.Journey.AirSegmentRef
                ).DepartureTime,
                "minutes"
              ) % 60}{" "}
              minutes
            </div>
          </div>

          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg text-black">
              {flight.Journey.AirSegmentRef instanceof Array
                ? flight.Journey.AirSegmentRef.length -
                  1 +
                  " Stops " +
                  `(${
                    getAirSegment(flight.Journey?.AirSegmentRef?.[0])
                      .Destination +
                    (flight.Journey.AirSegmentRef.length - 1 > 1
                      ? ", " +
                        getAirSegment(flight.Journey?.AirSegmentRef?.[1])
                          .Destination
                      : "")
                  })`
                : "Direct"}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5"></div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {totalPrice(flight._attributes.TotalPrice)}{" "}
                {/* {convertToNumber(flight._attributes.TotalPrice)}{" "} */}
                {flight._attributes.TotalPrice.substring(0, 3)}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              {/* round-trip  */}
              Flight-{" "}
              {
                getAirSegment(
                  flight.Journey.AirSegmentRef instanceof Array
                    ? flight.Journey.AirSegmentRef[0]
                    : flight.Journey.AirSegmentRef
                ).FlightNumber
              }
              {}
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default OneWay;
