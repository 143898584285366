import { useState } from "react";
import { Tab } from "@headlessui/react";
import { useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { addDateAndTime, calculateTime, mergeObjects } from "../utils";
import { getCarrierLogo } from "../../../utils/flightInfo";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import NDCMiniFareRuleModal from "../modals/NDCMiniFareRule";
import BaggageModal from "../modals/BaggageModal";
import { formatNumber } from "../../../utils/convertToNumber";
import {
  getServiceCharge,
  getTotalNDCPrice,
  groupByCabinType,
  processRules,
} from "../helpers";
import RenderRoute from "./RenderRoute";
import newLogo from "../../../images/new.png";

const ShoppingItems = ({
  segment,
  offer,
  oneWayLength,
  dataList,
  isSelected,
  onSelect,
  NDC_SC,
  company,
  corporate,
  discount,
}) => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const classType = query.get("classType");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCabin, setSelectedCabin] = useState(
    classType && classType === "Business" ? "BUSINESS" : "ECONOMY"
  );
  const [selectedFlightIndex, setSelectedFlightIndex] = useState(null);
  const [selectedCabinIndex, setSelectedCabinIndex] = useState(null);
  const [passengerIndex, setPassengerIndex] = useState(0);
  const [selectedOfferPrice, setSelectedOfferPrice] = useState(null);
  const [serviceCharge, setServiceCharge] = useState(0);

  const getClassType = (ref) => {
    return dataList?.PriceClassList?.PriceClass?.find(
      (item) => item.ObjectKey === ref
    )?.Name;
  };

  const getFareType = (ref) => {
    if (!ref) return;
    return dataList?.FareList?.FareGroup.find((item) => item.ListKey === ref);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [selectedIndex, setSelectedIndex] = useState(0);
  const cabinType = groupByCabinType(offer);

  useEffect(() => {
    if (!isSelected || selectedIndex) {
      setIsOpen(false);
    }
  }, [isSelected, selectedIndex]);

  function getPriceClass(ref) {
    return dataList?.PriceClassList?.PriceClass?.find(
      (item) => item.ObjectKey === ref
    );
  }

  function getClassOfService(offer) {
    return offer?.PricedOffer.Associations?.map((item) => {
      return item.ApplicableFlight.FlightSegmentReference.map((item) => {
        return item.ClassOfService.Code.value;
      });
    }).flat();
  }

  const {
    OriginDestinationList: { OriginDestination },
    AnonymousTravelerList: { AnonymousTraveler },
    CarryOnAllowanceList: { CarryOnAllowance } = {},
    CheckedBagAllowanceList: { CheckedBagAllowance } = {},
    PenaltyList: { Penalty } = {},
  } = dataList;

  const offerPrice = selectedOfferPrice?.[passengerIndex];
  const departRes = processRules(offerPrice, Penalty, 0);
  const returnRes = processRules(offerPrice, Penalty, 1);

  const merge = mergeObjects(departRes, returnRes);

  const travelerTypeCounts = { ADT: 0, CHD: 0, INF: 0, LBR: 0 };

  AnonymousTraveler.forEach((traveler) => {
    const type = traveler.PTC.value;
    if (travelerTypeCounts.hasOwnProperty(type)) {
      travelerTypeCounts[type]++;
    }
  });

  function getTravelerType(ref) {
    return AnonymousTraveler?.find((item) => item.ObjectKey === ref)?.PTC.value;
  }

  function getCarryOnBaggage(ref) {
    return CarryOnAllowance?.find((item) => item.ListKey === ref);
  }

  function getCheckedBaggage(ref) {
    return CheckedBagAllowance?.find((item) => item.ListKey === ref);
  }

  const popover = (item) => {
    return (
      <Popover
        id="popover-basic"
        className=" mt-2 rounded-lg border border-gray-200 bg-white shadow-lg"
      >
        <Popover.Header
          as="h3"
          className="bg-gray-300 px-2 text-sm font-medium text-black"
        >
          {
            getPriceClass(
              item?.PricedOffer?.Associations[0]?.PriceClass.PriceClassReference
            )?.Name
          }
        </Popover.Header>
        <Popover.Body className="flex flex-col space-y-1 p-2 ">
          {getPriceClass(
            item?.PricedOffer?.Associations[0]?.PriceClass.PriceClassReference
          )
            ?.Descriptions?.Description?.map((item) => item?.Text?.value)
            .map((item, index) => (
              <p key={index} className="text-sm font-light">
                {item}
              </p>
            ))}
        </Popover.Body>
      </Popover>
    );
  };

  const renderDetailTop = (flightInfo, index, showButton) => {
    const offerInfo = cabinType?.[selectedCabin]?.[selectedFlightIndex];
    let count = oneWayLength;
    const association =
      offerInfo?.PricedOffer?.Associations[count <= index ? 1 : 0];
    const fare =
      offerInfo?.PricedOffer?.OfferPrice?.map((item) => {
        return item?.FareDetail?.FareComponent;
      }) || [];

    const priceClass = association?.PriceClass;

    function getPriceClassReference(offer) {
      return offer?.PricedOffer.Associations?.map((item) => {
        return item.ApplicableFlight.FlightSegmentReference.map((item) => {
          return item.ClassOfService;
        });
      }).flat();
    }

    return (
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="w-32 flex-shrink-0 md:w-20 md:pt-7 lg:w-36 flex justify-center items-center">
            <img
              src={getCarrierLogo(flightInfo.MarketingCarrier.AirlineID.value)}
              className="w-15"
              alt=""
            />
          </div>
          <div className="my-5 flex md:my-0 lg:w-72">
            <div className="flex flex-shrink-0 flex-col items-center py-2">
              <span className="block h-6 w-6 rounded-full border border-neutral-400"></span>
              <span className="my-1 block flex-grow border-l border-dashed border-neutral-400"></span>
              <span className="block h-6 w-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-24 text-sm">
              <div className="flex flex-col space-y-1">
                <span className="text-xs font-light text-neutral-500">
                  {flightInfo?.Departure?.Time}
                </span>
                <span className="font-medium text-black">
                  {flightInfo?.Departure?.AirportCode?.value}
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className="text-xs font-light text-neutral-500">
                  {flightInfo?.Arrival?.Time}
                </span>
                <span className="font-medium text-black">
                  {flightInfo?.Arrival?.AirportCode?.value}
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 md:mx-6 lg:ml-24"></div>
          <div className="my-4 flex flex-row justify-between p-2 md:my-2">
            <ul className="space-y-1 text-sm font-extralight text-black md:space-y-2">
              <li>
                Fare:{" "}
                {fare
                  ?.map((item) => {
                    return getFareType(item?.[index]?.refs?.[0])?.FareBasisCode
                      ?.Code;
                  })
                  .join(", ")}
              </li>
              <li>
                Fare Type:{" "}
                {fare
                  ?.map((item) => {
                    return getFareType(item?.[index]?.refs?.[0])?.Fare
                      ?.FareDetail?.Remarks?.Remark?.[0]?.value;
                  })
                  .join(", ")}
              </li>
              <li>
                Flight time:{" "}
                <span>{flightInfo?.FlightDetail?.FlightDuration?.Value}</span>
              </li>
              <li>
                Flight Class:{" "}
                {
                  getPriceClassReference(offerInfo)?.[index]?.MarketingName
                    ?.value
                }
              </li>
              <li>
                Flight Number:{" "}
                {flightInfo?.MarketingCarrier?.FlightNumber?.value}
              </li>
              <li>
                Price Class: {getClassType(priceClass?.PriceClassReference)}
              </li>
              <li>
                Booking Class:{" "}
                {getPriceClassReference(offerInfo)?.[index]?.Code?.value}
              </li>
              <li>Operated By: {flightInfo?.OperatingCarrier?.Name}</li>
            </ul>
          </div>

          {showButton && (
            <>
              <div className="mx-auto"></div>
              <div className="mt-auto flex flex-col space-y-6">
                <div className="flex justify-start flex-col gap-2">
                  <NDCMiniFareRuleModal
                    segment={OriginDestination}
                    // penalty={{ 0: departRes, 1: returnRes }}
                    penalty={{ 0: merge.A, 1: merge.B }}
                    offerPrice={selectedOfferPrice}
                    getTravelerType={getTravelerType}
                    setPassengerIndex={setPassengerIndex}
                  />
                  <BaggageModal
                    segment={OriginDestination}
                    offerPrice={selectedOfferPrice}
                    getTravelerType={getTravelerType}
                    setPassengerIndex={setPassengerIndex}
                    passengerIndex={passengerIndex}
                    getCarryOnBaggage={getCarryOnBaggage}
                    getCheckedBaggage={getCheckedBaggage}
                  />
                </div>
                <button
                  // onClick={() => getPricing(offerInfo)}
                  onClick={() =>
                    onSelect(offerInfo, oneWayLength, serviceCharge, NDC_SC)
                  }
                  className="rounded-lg bg-amber-500 px-6 py-2 text-white"
                >
                  Select
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;

    return (
      <div className="my-3 rounded-2xl border border-neutral-200 p-4 md:mr-12 md:p-8">
        {/* {cabinType[selectedCabin]?.map((items, index) => ( */}
        {segment.map((flightInfo, index) => {
          const showButton = index === segment?.length - 1;
          return (
            <div key={index}>
              {renderDetailTop(flightInfo, index, showButton)}
              {index === segment?.length - 1 ? null : (
                <div className="my-7 space-y-5 md:my-10">
                  <div className="border-t border-neutral-300" />
                  <div className="flex justify-between text-base font-light text-black">
                    {oneWayLength - 1 !== index ? (
                      "Transit time: Transit time: " +
                      calculateTime(
                        addDateAndTime(
                          segment?.[index + 1]?.Departure?.Date,
                          segment?.[index + 1]?.Departure?.Time
                        ),
                        addDateAndTime(
                          segment?.[index]?.Arrival?.Date,
                          segment?.[index]?.Arrival?.Time
                        )
                      )
                    ) : (
                      <p className="text-sm font-light text-red-500">
                        Return Flight
                      </p>
                    )}
                  </div>
                  <div className="border-t border-neutral-300" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`nc-FlightCardgroup relative overflow-hidden rounded-2xl border border-neutral-100 bg-white shadow transition-shadow hover:shadow-lg space-y-6 `}
      data-nc-id="FlightCard"
    >
      <img
        src={newLogo}
        alt="Logo"
        className="absolute w-[90px] -top-3 -left-3"
      />
      <div className="border-b border-slate-200 px-4 py-2 leading-8">
        <div className="flex-shrink-0 flex  items-center">
          <img
            src={getCarrierLogo(
              segment?.[0]?.MarketingCarrier?.AirlineID?.value
            )}
            className="w-12 h-8 object-contain"
            alt=""
          />
          <h1>{segment[0]?.MarketingCarrier?.Name}</h1>
        </div>
      </div>
      <div className={`relative px-4 pb-1 sm:pr-20 `} data-nc-id="FlightCard">
        <div
          className={`flex flex-col space-y-6 ${
            segment.length > 1 ? "mt-2 sm:items-center" : "sm:items-center"
          } md:space-y-0 xl:flex-row`}
        >
          <RenderRoute segment={segment} oneWayLength={oneWayLength} />
          {/* TYPE */}
          <div className="w-full flex-[3] whitespace-nowrap">
            <div className="mx-auto w-full max-w-2xl sm:px-0 md:px-4">
              <Tab.Group
                selectedIndex={selectedIndex}
                // onChange={setSelectedIndex}
              >
                <Tab.List className="flex space-x-1 rounded-lg  p-[2px] md:mx-4">
                  {Object.keys(groupByCabinType(offer)).map((tab, index) => (
                    <Tab
                      key={index}
                      onClick={() => {
                        setSelectedCabin(tab);
                        setSelectedIndex(index);
                      }}
                      className={({ selected }) =>
                        classNames(
                          "w-full py-1.5 rounded-lg text-sm font-medium leading-5",
                          "focus:outline-none",
                          selected
                            ? "bg-white text-black border-1 border-main"
                            : "text-black hover:bg-white/[0.6] hover:text-gray-900 bg-neutral-200/75 border-1 border-neutral-200"
                        )
                      }
                    >
                      {tab}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels>
                  {Object.keys(cabinType)?.map((offer, index) => {
                    let items = cabinType[offer];

                    return (
                      <Tab.Panel
                        className={classNames("rounded-lg pt-1 md:px-2")}
                        key={index}
                      >
                        <div className="no-scrollbar relative flex h-auto justify-between gap-3  p-2 text-center text-lg font-medium text-black overflow-auto">
                          {items?.map((item, idx) => {
                            const cabinType =
                              item?.PricedOffer?.Associations[0]
                                ?.ApplicableFlight?.FlightSegmentReference[0]
                                ?.ClassOfService?.MarketingName
                                ?.CabinDesignator;

                            const charge = getServiceCharge(
                              item?.PricedOffer?.OfferPrice,
                              AnonymousTraveler,
                              company,
                              corporate,
                              cabinType,
                              {},
                              discount
                            );

                            return (
                              <div className="w-full" key={idx}>
                                <button
                                  className={classNames(
                                    "w-full transform rounded-lg transition duration-500 hover:scale-95 ",
                                    "py-2 ",
                                    selectedFlightIndex === idx &&
                                      isSelected &&
                                      selectedCabinIndex === index
                                      ? "border-2 border-main text-black"
                                      : "border-1 border-main-800"
                                  )}
                                  onClick={() => {
                                    setSelectedFlightIndex(idx);
                                    setSelectedCabinIndex(index);
                                    setIsOpen(true);
                                    setSelectedOfferPrice(
                                      item?.PricedOffer?.OfferPrice
                                    );
                                    setServiceCharge(charge);
                                  }}
                                >
                                  <p className="px-1 text-base font-light text-amber-500">
                                    {getClassType(
                                      item?.PricedOffer?.Associations[0]
                                        ?.PriceClass?.PriceClassReference
                                    )}
                                  </p>
                                  <h1
                                    className={`text-base  ${
                                      idx === 0 && index === 0
                                        ? "font-semibold "
                                        : "font-normal"
                                    }`}
                                  >
                                    {
                                      item?.TotalPrice?.SimpleCurrencyPrice
                                        ?.Code
                                    }{" "}
                                    {formatNumber(
                                      getTotalNDCPrice(
                                        item?.TotalPrice?.SimpleCurrencyPrice
                                          ?.value,
                                        charge,
                                        NDC_SC
                                      )
                                    )}
                                  </h1>
                                  <p className="font-thin text-neutral-700">
                                    {
                                      item?.PricedOffer.Associations[0]
                                        .ApplicableFlight
                                        .FlightSegmentReference[0]
                                        .ClassOfService.MarketingName.value
                                    }
                                  </p>

                                  <div className="flex justify-center ">
                                    <div className="mt-2 flex justify-center">
                                      {getClassOfService(item).map(
                                        (c, index) => (
                                          <div
                                            key={index}
                                            className="absolute bottom-0 -mb-2 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-500 text-center text-xs text-white"
                                            style={{
                                              left: `calc(50% + ${
                                                index * 20
                                              }px - ${
                                                (getClassOfService(item)
                                                  .length -
                                                  1) *
                                                12.5
                                              }px)`,
                                            }}
                                          >
                                            {c}
                                          </div>
                                        )
                                      )}
                                    </div>

                                    {selectedFlightIndex === idx &&
                                      isSelected &&
                                      selectedCabinIndex === index && (
                                        <FaCheckCircle className="absolute -right-2 -top-2 flex w-5 h-5 items-center justify-center rounded-full bg-white text-center text-xs text-green-500" />
                                      )}
                                  </div>
                                </button>
                                <div className="mt-3 border-1 border-neutral-300 flex gap-2">
                                  <OverlayTrigger
                                    trigger="click"
                                    placement="left"
                                    overlay={popover(item)}
                                    rootClose
                                  >
                                    <button className="w-full px-2 text-sm mt-1 font-thin text-black transition duration-300">
                                      <div className="flex items-center justify-center gap-1">
                                        <IoIosInformationCircleOutline className="h-4 w-4 text-red-500" />
                                        <span>More Info</span>
                                      </div>
                                    </button>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Tab.Panel>
                    );
                  })}
                </Tab.Panels>
              </Tab.Group>
            </div>

            <div className="mt-0.5 text-sm font-normal text-neutral-500"></div>
          </div>
        </div>
        {renderDetail()}
      </div>
    </div>
  );
};

export default ShoppingItems;
