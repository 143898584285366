import moment from "moment";
import { toast } from "react-toastify";
import { Tab } from "@headlessui/react";
import countryList from "country-list";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getBookingById } from "../../../redux2/booking/bookingSlice";
import { getMe, getMyCompany } from "../../../redux2/user/userSlice";
import {
  FOREIGN_PASSENGER,
  INTERNATIONAL_SYSTEM_FEE,
  LOCAL_SYSTEM_FEE,
  SUPER_ROLE,
  USER_ROLE_AGENT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_ENTERPRISE,
  USER_ROLE_MEMBER,
  USER_ROLE_SUPERAGENT,
  WALLET_MINIMUM_AMOUNT,
} from "../../../common/constants";
import { getMyEnterprise } from "../../../redux2/enterprise/enterpriseSlice";
import { getCorporate } from "../../../redux2/corporate/corporateSlice";
import { currencyType, getCarrierLogo } from "../../../utils/flightInfo";
import Loading from "../../Loading";
import NcImage from "../../NcImage/NcImage";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import Label from "../../Label/Label";
import Input from "../../Input/Input";
import ButtonPrimary from "../../Button/ButtonPrimary";
import NDCBookingReq from "./BookingReq";
import { getNDCPassengerPrice, getPriceEach } from "../helpers";

const Booking = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const labors = query.get("labors");
  const tripType = query.get("tripType");
  const isLocal = query.get("isLocal");
  const passengerTypeState = query.get("passengerTypeState");
  const _id = query.get("_id");

  const {
    state: { pricing, fees },
  } = useLocation();

  const [travelerTypes, setTravelerTypes] = useState([]);

  const {
    DataLists: {
      FlightSegmentList: { FlightSegment },
      AnonymousTravelerList: { AnonymousTraveler },
    },
  } = pricing;

  let totalPassengers = 0;

  const travelerTypeCounts = { ADT: 0, CHD: 0, INF: 0, LBR: 0 };

  AnonymousTraveler.forEach((traveler) => {
    const type = traveler.PTC.value;
    if (travelerTypeCounts.hasOwnProperty(type)) {
      travelerTypeCounts[type]++;
    }
  });

  totalPassengers = Object.values(travelerTypeCounts).reduce(
    (acc, cur) => acc + cur,
    0
  );

  useEffect(() => {
    const types = [];
    for (const type in travelerTypeCounts) {
      types.push(
        ...Array.from({ length: travelerTypeCounts[type] }, () => type)
      );
    }
    setTravelerTypes(types);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTravelerType(ref) {
    return AnonymousTraveler?.find((item) => item.ObjectKey === ref)?.PTC.value;
  }

  let basePrice = 0;
  pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice?.map((item) => {
    basePrice +=
      item.RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.value *
        item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
          .length || 1;
  });
  const currency =
    pricing?.PricedFlightOffers.PricedFlightOffer[0].OfferPrice[0].RequestedDate
      .PriceDetail.TotalAmount.SimpleCurrencyPrice.Code;

  const price =
    pricing?.PricedFlightOffers?.PricedFlightOffer[0].OfferPrice[0]
      .RequestedDate.PriceDetail;

  const { booking, isLoading: bookingLoading } = useSelector(
    (state) => state.booking
  );
  const { company, isLoading, user } = useSelector((state) => state.user);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { corporate, isLoading: corpLoading } = useSelector(
    (state) => state.corporate
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [warning, setWarning] = useState("");
  const [commission, setCommission] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);

  const [formData, setFormData] = useState([
    // {
    //   FirstName: "merry",
    //   MiddleName: "",
    //   LastName: "Doe",
    //   Email: "merry@gmail.com",
    //   phone: "900000007",
    //   Location: "ETH",
    //   Nationality: "",
    //   AreaCode: "251",
    //   CountryCode: "251",
    //   DOB: "2000-02-02",
    //   Gender: "M",
    //   Prefix: "",
    // },
    // {
    //   FirstName: "Jane",
    //   MiddleName: "",
    //   LastName: "Joe",
    //   Email: "jane@gmail.com",
    //   phone: "900000008",
    //   Location: "ETH",
    //   Nationality: "",
    //   AreaCode: "251",
    //   CountryCode: "251",
    //   DOB: "2000-01-01",
    //   Gender: "",
    // },
    {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Email: "",
      phone: "",
      Location: "ETH",
      Nationality: "Ethiopia",
      AreaCode: "251",
      CountryCode: "251",
      DOB: "",
      Gender: "",
      Prefix: "",
      OriginId: "",
      ResidentId: "",
      // address
      AddressName: "",
      Street: "",
      City: "",
      State: "",
      PostalCode: "",
      Country: "",
    },
  ]);

  const [passportData, setPassportData] = useState([
    {
      issueDate: "",
      expiryDate: "",
      issueCountry: "",
      passportNumber: "",
      SupplierCode: "",
      CardNumber: "",
      DocumentType: "P",
      DocumentNumber: "",
      ResidentCountry: "ET",
      ProviderCountry: "ET",
      DateOfBirth: "10OCT19",
      passport: "",
      idCard: "",
      certificate: "",
      other: "",
      ssr: [],
      // visa: "",
    },
  ]);

  useEffect(() => {
    if (_id) {
      dispatch(getBookingById(_id));
    }
  }, [dispatch, _id]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    user?.role === USER_ROLE_ENTERPRISE
      ? dispatch(getMyEnterprise())
      : dispatch(getMyCompany());
  }, [dispatch, user?.role]);

  useEffect(() => {
    if (company?.corporate || enterprise?.corporate) {
      dispatch(getCorporate(company?.corporate || enterprise?.corporate));
    } else {
      let id =
        user?.role === USER_ROLE_EMPLOYEE
          ? user?.corporate
          : company?.corporate;
      dispatch(getCorporate(id));
    }
  }, [dispatch, company, user, enterprise]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError("");
    }
    // if password expired date is less than 6 months show error
    passportData.forEach((passport) => {
      if (passport.expiryDate) {
        let expiryDate = moment(passport.expiryDate);
        let today = moment();
        let diff = expiryDate.diff(today, "months");
        if (diff < 6) {
          setWarning("Your passport is about to expire");
        } else {
          setWarning("");
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, dispatch, passportData]);

  useEffect(() => {
    if (booking?.passengers) {
      setFormData([]);
      setPassportData([]);
      Array(totalPassengers)
        .fill()
        .map((_, index) => {
          setFormData((prev) => {
            return [
              ...prev,
              {
                Prefix: booking?.passengers[index]?.title,
                FirstName: booking?.passengers[index]?.firstName,
                MiddleName: booking?.passengers[index]?.middleName,
                LastName: booking?.passengers[index]?.lastName,
                Email: booking?.passengers[index]?.email,
                phone: +booking?.passengers[index]?.phoneNumber
                  ?.toString()
                  ?.slice(3),
                Location: booking?.passengers[index]?.location,
                Nationality: booking?.passengers[index]?.nationality,
                DOB: booking?.passengers[index]?.dob?.split("T")[0],
                Gender:
                  booking?.passengers[index]?.gender === "Male" ? "M" : "F",
              },
            ];
          });
          setPassportData((prev) => {
            return [
              ...prev,
              {
                DocumentNumber: booking?.passengers[index]?.passportNumber,
                expiryDate:
                  booking?.passengers[index]?.passportExpiry?.split("T")[0],
              },
            ];
          });
        });
    }
  }, [booking?.passengers, totalPassengers]);

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      [name]: value,
    };
    setFormData(newFormData);

    // setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getMinDate = (type) => {
    const today = new Date();
    let minDate;

    if (type === "INF") {
      minDate = new Date(
        today.getFullYear() - 2,
        today.getMonth(),
        today.getDate()
      );
    } else if (type === "CNN") {
      minDate = new Date(
        today.getFullYear() - 12,
        today.getMonth(),
        today.getDate()
      );
    } else {
      return "";
    }

    return minDate.toISOString().split("T")[0];
  };

  const getMaxDate = (type) => {
    const today = new Date();
    let maxDate;

    if (type === "ADT") {
      maxDate = new Date(
        today.getFullYear() - 12,
        today.getMonth(),
        today.getDate()
      );
    } else {
      maxDate = new Date();
    }

    return maxDate.toISOString().split("T")[0];
  };

  async function handleAcceptedFiles(e, index) {
    const { name, files } = e.target;
    if (files.length > 3) {
      alert("You can only upload 3 images");
      return;
    }
    if (files[0].size > 1024 * 1024 * 5) {
      alert("Image size should be less than 5MB");
      return;
    }
    const newPassportData = [...passportData];
    newPassportData[index] = {
      ...newPassportData[index],
      [name]: files[0],
      // [name]: await fileToBase64(files[0]),
    };
    setPassportData(newPassportData);
  }

  const onPassportChange = (e, index) => {
    const { name, value } = e.target;
    const newPassportData = [...passportData];
    newPassportData[index] = {
      ...newPassportData[index],
      [name]: value,
    };
    setPassportData(newPassportData);
    // setPassportData({ ...passportData, [e.target.name]: e.target.value });
  };

  function getSystemFee() {
    if (isLocal === "true")
      return LOCAL_SYSTEM_FEE * (+adults + +children + +labors);
    else {
      return user?.role === USER_ROLE_SUPERAGENT ||
        user?.role === USER_ROLE_EMPLOYEE
        ? INTERNATIONAL_SYSTEM_FEE * (+adults + +children + +labors)
        : // : passengerTypeState === NON_DOCUMENT_HOLDER
        currencyType(currency) === "USD"
        ? corporate?.exchangeRate * (fees?.serviceCharge + fees.NDC_SC) * 0.2
        : (fees?.serviceCharge + fees.NDC_SC) * 0.2;
    }
  }

  function getServiceCharge() {
    // if (passengerTypeState === NON_DOCUMENT_HOLDER) {
    // if (currencyType(currency) === "USD") {
    if (currency === "USD") {
      return fees?.serviceCharge > 0
        ? fees?.serviceCharge * corporate?.exchangeRate
        : serviceCharge * corporate?.exchangeRate;
    } else {
      return fees?.serviceCharge > 0 ? fees?.serviceCharge : serviceCharge;
    }
  }

  const Travelers =
    pricing?.DataLists?.AnonymousTravelerList?.AnonymousTraveler;

  const sortPassengerData = (data) => {
    const order = { ADT: 1, CHD: 2, INF: 3 };
    return data.sort((a, b) => order[a.PTC.value] - order[b.PTC.value]);
  };

  function getPassengerType(PTC) {
    return pricing?.PricedFlightOffers?.PricedFlightOffer?.[0]?.OfferPrice?.[0]?.RequestedDate?.Associations?.map(
      (association) => {
        return association.AssociatedTraveler.TravelerReferences?.find(
          (item) => item === PTC
        );
      }
    );
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (user?.role === SUPER_ROLE && serviceCharge < 1) {
      toast.error("Service Charge must be greater than 0");
      return;
    } else if (
      (user?.role === USER_ROLE_AGENT || user?.role === USER_ROLE_MEMBER) &&
      commission < 1
    ) {
      toast.error("Agent Fee must be greater than 0");
      return;
    }

    if (
      isLocal === "true" &&
      (user?.role === USER_ROLE_MEMBER || user?.role === USER_ROLE_AGENT)
    ) {
      let hasPassportOrIdCard = false;
      passportData.forEach((data) => {
        if (data?.passport !== "" || data?.idCard !== "") {
          hasPassportOrIdCard = true;
        }
      });
      if (!hasPassportOrIdCard) {
        toast.error("Please upload passport or id card");
        return;
      }
    }

    const response = NDCBookingReq(pricing, formData, passportData, basePrice);

    const OD = pricing?.DataLists?.OriginDestinationList?.OriginDestination;

    let bookingData2 = {
      TotalAmount:
        basePrice +
        parseFloat(fees?.serviceCharge || 0) +
        parseFloat(fees?.NDC_SC || 0),
      passengers: [],
      SpecificSeatAssignment: [],

      OtherInfo: {
        ServiceCharge: +getServiceCharge(),
        USDServiceCharge:
          fees?.serviceCharge > 0 ? +fees?.serviceCharge : +serviceCharge,
        Commission: commission,
        SystemFee: +getSystemFee()?.toFixed(2) || 0,
        TripType: tripType,
        isRoundTrip: OD?.length > 1 ? true : false,
        Destination: OD?.[0]?.ArrivalCode?.value,
        company: company?._id,
        currency,
        charges: fees?.serviceCharge ? fees?.serviceCharge : 0,
        NDC_SC: fees?.NDC_SC,
        user: {
          id: user?._id,
          role: user?.role,
        },
        issuedAt: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      },
    };

    const passengerPrices = getPriceEach(
      pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
    );

    const individualPrices = passengerPrices?.flatMap(
      ({ type, price, count }) => Array(count).fill(price / count)
    );

    for (let passenger in formData) {
      bookingData2.passengers.push({
        Prefix: formData[passenger].Prefix,
        FirstName: formData[passenger].FirstName.trim(),
        MiddleName: formData[passenger].MiddleName.trim(),
        LastName: formData[passenger].LastName.trim(),
        Email: formData[passenger].Email,
        OriginId: formData[passenger].OriginId,
        ResidentId: formData[passenger].ResidentId,
        PhoneNumber: {
          AreaCode: "251",
          CountryCode: "251",
          Location: "ETH",
          Number: formData[passenger].phone,
        },
        DOB: formData[passenger].DOB,
        Gender: formData[passenger].Gender || "M",
        Nationality: formData[passenger].Nationality || "Ethiopia",
        passportData: passportData[passenger]?.DocumentNumber,
        Price: individualPrices[passenger],

        Document: {
          ExpiryDate: passportData[passenger]?.expiryDate
            ? moment(passportData[passenger]?.expiryDate).format("DDMMMYY")
            : "10OCT30",
          BirthDay: moment(formData[passenger]?.DOB).format("DDMMMYY"),
          DocumentType: "P",
          DocumentNumber:
            passportData[passenger]?.DocumentNumber || "ET1234567",
          ResidentCountry: "ET",
          ProviderCountry: "ET",
        },
      });
    }

    let files = [];
    for (let i = 0; i < passportData.length; i++) {
      if (passportData[i]?.passport) {
        files.push(passportData[i]?.passport);
      }
      if (passportData[i]?.idCard) {
        files.push(passportData[i]?.idCard);
      }

      if (passportData[i]?.certificate) {
        files.push(passportData[i]?.certificate);
      }

      if (passportData[i]?.other) {
        files.push(passportData[i]?.other);
      }
    }

    const data = new FormData();
    data.append("bookingData", JSON.stringify(bookingData2));
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }

    const flight = {
      FlightSegment,
      pricing,
    };

    if (response) {
      navigate(`/ndc-instant-payment?${query}`, {
        state: {
          response,
          flight,
          basePrice,
          passengersData: bookingData2,
          files,
        },
      });
    }
  };

  if (loading || bookingLoading || isLoading || corpLoading) {
    return <Loading />;
  }

  function totalPrice() {
    let result =
      +basePrice +
      (fees?.serviceCharge || 0) +
      parseFloat(serviceCharge || 0) +
      fees?.NDC_SC;

    return result;
  }

  const isRequired = (isLocal, user, i) => {
    if (
      user?.role === USER_ROLE_SUPERAGENT ||
      user?.role === USER_ROLE_EMPLOYEE
    ) {
      return false;
    }

    return true;
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 shadow space-y-6 sm:space-y-8 p-5 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <NcImage
              src={getCarrierLogo(
                FlightSegment?.[0]?.MarketingCarrier?.AirlineID?.value
              )}
            />
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <span className="text-lg text-neutral-500 font-semibold">
              <h1>{FlightSegment?.[0]?.MarketingCarrier?.AirlineID?.value}</h1>
            </span>

            {FlightSegment.map((segment, index) => (
              <div key={index}>
                <div className="text-lg text-neutral-500 font-semibold line-clamp-1">
                  <h1>
                    {segment.Departure?.AirportCode.value} -{" "}
                    {segment.Arrival?.AirportCode.value}
                  </h1>
                </div>
                <span className="text-base font-medium mt-1 block">
                  {moment(segment.Departure?.Date).format("DD MMM YYYY")} -
                  {moment(segment.Arrival?.Date).format("DD MMM YYYY")}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {segment.Departure?.Time} - {segment.Arrival?.Time}
                </span>
              </div>
            ))}

            <div className="flex flex-row justify-start gap-3">
              <span className="block  text-sm text-neutral-500">
                {travelerTypeCounts?.ADT > 0}{" "}
                {travelerTypeCounts?.ADT + " Adults"}
              </span>
              {travelerTypeCounts?.CHD > 0 && (
                <span className="block  text-sm text-neutral-500">
                  {travelerTypeCounts?.CHD + " Children"}
                </span>
              )}
              <span className="block  text-sm text-neutral-500">
                {travelerTypeCounts?.INF > 0 &&
                  travelerTypeCounts?.INF + " Infants"}
              </span>
            </div>

            <div className="w-10 border-b border-neutral-200  "></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex flex-col justify-between text-neutral-600 gap-1">
            {getPriceEach(
              pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
            )?.map((item, index) => {
              return (
                <div key={index} className="flex justify-between">
                  <p>
                    {item.count} * {getTravelerType(item.type)}
                  </p>
                  <p>
                    {/* {item.price} INR */}
                    {/* {convertNumbThousand(item.price)} {currency} */}
                    {convertNumbThousand(
                      getNDCPassengerPrice(
                        item,
                        adults,
                        children,
                        fees,
                        getTravelerType(item.type)
                      )
                    )}{" "}
                    {currency}
                  </p>
                </div>
              );
            })}
          </div>
          {/* <div className="flex justify-between text-neutral-600">
            <div>
              {" "}
              {travelerTypeCounts?.ADT > 0
                ? getTravelerType(
                    getPriceEach(
                      pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
                    )[0].type
                  )
                : travelerTypeCounts?.LBR + " * Labors "}
            </div>
            <div>
              {price.TotalAmount.SimpleCurrencyPrice.Code}{" "}
              {
                getPriceEach(
                  pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
                )[0].price
              }{" "}
            </div>
          </div>
          {travelerTypeCounts?.CHD > 0 && (
            <div className="flex justify-between text-neutral-600">
              <div>
               
                {getTravelerType(
                  getPriceEach(
                    pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
                  )[1].type
                )}
              </div>
              <div>
                {price.TotalAmount.SimpleCurrencyPrice.Code}{" "}
                {
                  getPriceEach(
                    pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
                  )[1].price
                }
              </div>
            </div>
          )}
          {travelerTypeCounts?.INF > 0 && (
            <div className="flex justify-between text-neutral-600">
              <div>
                
                {getTravelerType(
                  getPriceEach(
                    pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
                  )[travelerTypeCounts?.CHD > 0 ? 2 : 1].type
                )}
              </div>
              <div>
                {price.TotalAmount.SimpleCurrencyPrice.Code}{" "}
                {
                  getPriceEach(
                    pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
                  )[travelerTypeCounts?.CHD > 0 ? 2 : 1].price
                }
              </div>
            </div>
          )} */}

          <div className="flex justify-between text-neutral-600">
            <span>Service charge</span>
            {user?.role === USER_ROLE_SUPERAGENT ||
            user?.role === USER_ROLE_EMPLOYEE ? (
              <span>
                <input
                  type="number"
                  min={1}
                  onWheel={(e) => e.target.blur()}
                  className="w-20 border border-black rounded-md p-1"
                  value={serviceCharge}
                  onChange={(e) => setServiceCharge(e.target.value)}
                  required
                />{" "}
                {/* {currencyType(currency)} */}
                {currency}
              </span>
            ) : (
              <span className="text-red-500">
                {fees?.serviceCharge || 0} {currencyType(currency)}
              </span>
            )}
          </div>
          <div className="flex justify-between text-neutral-600">
            <span>Agent Fee</span>
            {user?.role === USER_ROLE_SUPERAGENT ||
            user?.role === USER_ROLE_EMPLOYEE ||
            user?.role === USER_ROLE_ENTERPRISE ? (
              <span>0 birr</span>
            ) : (
              <span className="text-red-500">
                <input
                  type="number"
                  min={1}
                  onWheel={(e) => e.target.blur()}
                  className="w-20 border border-black rounded-md p-1 text-black"
                  value={commission}
                  onChange={(e) => setCommission(e.target.value)}
                  required
                />{" "}
                {currencyType(currency)}
              </span>
            )}
          </div>

          <div className="border-b border-neutral-200 "></div>
          <div className="flex justify-between font-bold text-xl text-black">
            <span>Total Price</span>
            <span>
              {price.TotalAmount.SimpleCurrencyPrice.Code}{" "}
              {convertNumbThousand(totalPrice() + parseFloat(commission || 0))}{" "}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = (index, type) => {
    return (
      <div className="mt-6">
        <Tab.Group>
          <Tab.Panels>
            <Tab.Panel className=" text-lg">
              <div className="row">
                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <div className="flex flex-row gap-3">
                      <div className="flex flex-col ">
                        <Label>Title</Label>
                        <div className="flex flex-row gap-1">
                          <select
                            className="block border text-black text-[15px] border-black w-20 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white h-10 mt-1"
                            style={{
                              display:
                                type !== "ADT" && type !== "LBR" && "none",
                            }}
                            name="Prefix"
                            value={formData[index]?.Prefix || ""}
                            onChange={(e) => onChange(e, index)}
                          >
                            <option value="" selected></option>
                            <option
                              value="Mr"
                              onChange={(e) => onChange(e, index)}
                            >
                              Mr.
                            </option>
                            <option
                              value="Mrs"
                              onChange={(e) => onChange(e, index)}
                            >
                              Mrs.
                            </option>
                            <option
                              value="Ms"
                              onChange={(e) => onChange(e, index)}
                            >
                              Ms.
                            </option>
                            {/* <option
                              value="Dr"
                              onChange={(e) => onChange(e, index)}
                            >
                              Dr.
                            </option>
                            <option
                              value="Prof"
                              onChange={(e) => onChange(e, index)}
                            >
                              Prof.
                            </option>
                            <option
                              value="Amb"
                              onChange={(e) => onChange(e, index)}
                            >
                              Amb.
                            </option> */}
                          </select>
                        </div>
                      </div>

                      <div className="flex flex-col gap-1 w-full">
                        <Label>
                          First Name{" "}
                          <span className="py-1 text-red-500">*</span>
                        </Label>
                        <Input
                          placeholder="Enter first name"
                          className="w-full"
                          name="FirstName"
                          value={formData[index]?.FirstName || ""}
                          onChange={(e) => onChange(e, index)}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>Middle Name </Label>
                    <Input
                      // className="w-1/2"
                      placeholder="Enter middle name"
                      name="MiddleName"
                      value={formData[index]?.MiddleName || ""}
                      onChange={(e) => onChange(e, index)}
                    />
                    {/* </div> */}
                  </div>
                </div>

                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>
                      Last Name <span className="py-1 text-red-500">*</span>
                    </Label>
                    <Input
                      // className="w-1/2"
                      placeholder="Enter last name"
                      name="LastName"
                      value={formData[index]?.LastName || ""}
                      onChange={(e) => onChange(e, index)}
                      required={true}
                      // required={isLocal === "true" ? false : true}
                    />
                    {/* </div> */}
                  </div>
                </div>
                <div
                  className="col-md-6 my-2"
                  style={{
                    display: type !== "ADT" && type !== "LBR" && "none",
                  }}
                >
                  <Label>
                    Email <span className="py-1 text-red-500">*</span>
                  </Label>
                  <Input
                    // className="w-1/2"
                    placeholder="Enter email"
                    value={formData[index]?.Email || ""}
                    onChange={(e) => onChange(e, index)}
                    name="Email"
                    required={type === "ADT"}
                    type="email"
                  />
                </div>

                <div
                  className="col-md-6 my-2"
                  style={{
                    display: type !== "ADT" && type !== "LBR" && "none",
                  }}
                >
                  <div className="space-y-0">
                    <Label>
                      Phone Number <span className="py-1 text-red-500">*</span>
                    </Label>
                    <div className="flex flex-row">
                      <select
                        className="block border text-black text-[15px] border-black w-20 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white"
                        name="AreaCode"
                        value={formData[index]?.AreaCode || ""}
                        onChange={(e) => onChange(e, index)}
                      >
                        <option>+251</option>
                        <option>+1</option>
                        <option>+40</option>
                        <option>+97</option>
                      </select>
                      {/* <Input defaultValue="+251" className="w-24" /> */}
                      <Input
                        defaultValue="900000000"
                        placeholder="Enter phone number"
                        // className="w-1/3"
                        type="Number"
                        name="phone"
                        value={formData[index]?.phone || ""}
                        onChange={(e) => onChange(e, index)}
                        required={type === "ADT"}
                        minLength={9}
                        maxLength={9}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>
                      Nationality <span className="py-1 text-red-500">*</span>
                    </Label>
                    <select
                      defaultValue="Ethiopia"
                      className="block border text-black text-[15px] border-black w-1/2 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white p-2"
                      name="Nationality"
                      onChange={(e) => onChange(e, index)}
                    >
                      {countryList.getNames().map((country) => (
                        <option
                          selected={country === "Ethiopia"}
                          defaultValue="Ethiopia"
                          key={country}
                          value={country}
                        >
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="flex-1 space-y-0">
                    <Label>
                      Date of birth <span className="py-1 text-red-500">*</span>
                    </Label>
                    <Input
                      type="date"
                      // className="w-1/2"
                      name="DOB"
                      value={formData[index]?.DOB || ""}
                      onChange={(e) => onChange(e, index)}
                      required={true}
                      min={getMinDate(type)}
                      max={getMaxDate(type)}
                      // add validation for age if type is CNN age between 2-11 and if type is INF age between 0-2
                    />
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>
                      Gender <span className="py-1 text-red-500">*</span>
                    </Label>
                    <select
                      className="block border text-black text-[15px] border-black w-1/2 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white p-2"
                      name="Gender"
                      onChange={(e) => onChange(e, index)}
                    >
                      <option
                        value="M"
                        selected
                        onChange={(e) => onChange(e, index)}
                      >
                        Male
                      </option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                </div>
                {passengerTypeState === FOREIGN_PASSENGER && (
                  <div className="col-md-6 my-2">
                    <div className="space-y-0">
                      <Label>
                        Origin ID NO.
                        <p className="text-red-500/80 text-xs">
                          Note: Please note that if your customer is not an
                          Ethiopian national and has an Ethiopian origin ID,
                          please insert the ID number in the box provided.
                        </p>{" "}
                      </Label>
                    </div>
                    <Input
                      placeholder="Ethiopian Origin ID No."
                      name="OriginId"
                      value={formData[index]?.OriginId}
                      onChange={(e) => onChange(e, index)}
                    />
                  </div>
                )}

                {/* {(AirSegment.Carrier || AirSegment[0]._attributes.Carrier) ===
                  "TK" && (
                  <div className="space-y-2 my-4">
                    <h1 className="font-bold text-black text-2xl">
                      Passenger Destination Address
                    </h1>

                    <div className="row border-1 border-slate-400 p-2 rounded-md">
                      <h6 className="text-red-500 text-base">
                        * All Fields are Mandatory for <b>Turkish Airlines</b>{" "}
                      </h6>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>Address Name </Label>
                        </div>
                        <Input
                          placeholder="Address Name"
                          // className="w-1/2"
                          name="AddressName"
                          value={passportData.addressName}
                          onChange={(e) => onChange(e, index)}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="space-y-0">
                          <Label>Street</Label>
                          <Input
                            type="text"
                            placeholder="Street"
                            name="Street"
                            value={passportData.street}
                            onChange={(e) => onChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="space-y-0">
                          <Label>City</Label>
                          <Input
                            type="text"
                            placeholder="City"
                            name="City"
                            value={passportData.city}
                            onChange={(e) => onChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>State</Label>
                        </div>
                        <Input
                          type="text"
                          name="State"
                          placeholder="State"
                          onChange={(e) => onChange(e, index)}
                        />
                      </div>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>Postal Code </Label>
                        </div>
                        <Input
                          type="text"
                          name="PostalCode"
                          placeholder="Postal Code"
                          onChange={(e) => onChange(e, index)}
                        />
                      </div>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>Country Code </Label>
                        </div>
                        <Input
                          type="text"
                          placeholder="Country"
                          name="Country"
                          onChange={(e) => onChange(e, index)}
                          // className="w-1/2"
                        />
                        <p className="text-sm">
                          (Eg. insert "FR" - if your destination city is in
                          France)
                        </p>
                      </div>
                    </div>
                  </div>
                )} */}

                <div className="space-y-2">
                  <h1 className="font-bold text-black text-2xl">
                    Passport Details
                  </h1>
                  {/* show warring */}
                  {warning && (
                    <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                      <i className="fas fa-exclamation-triangle mr-2"></i>
                      Passport is about to expire. or check the passport expiry
                      date input again.
                    </p>
                  )}
                  <div className="row border-1 border-slate-400 p-2 rounded-md">
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>Passport Number </Label>
                        <span className="py-1 text-red-500">*</span>
                      </div>
                      <Input
                        placeholder="Passport Number"
                        // className="w-1/2"
                        name="DocumentNumber"
                        value={passportData?.[index]?.DocumentNumber || ""}
                        onChange={(e) => onPassportChange(e, index)}
                        required={isLocal === "true" ? false : true}
                      />
                    </div>

                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>Passport Expire Date</Label>
                        <span className="py-1 text-red-500">*</span>
                        <Input
                          type="date"
                          // className="w-1/2"
                          required={isLocal === "true" ? false : true}
                          name="expiryDate"
                          value={passportData?.[index]?.expiryDate || ""}
                          onChange={(e) => onPassportChange(e, index)}
                        />
                      </div>
                    </div>
                    {isLocal === "false" && (
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label> Upload Passport </Label>
                        </div>
                        <input
                          // accept="image/*"
                          accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                          // style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          name="passport"
                          onChange={(e) => handleAcceptedFiles(e, index)}
                          required={isRequired(isLocal, user, index)}
                          // onChange={(e) => onPassportChange(e, index)}
                          // className="w-1/2"
                        />
                        <Label className="text-gray-600">
                          Upload a file with Max size of 5MB
                        </Label>
                        {user?.role !== USER_ROLE_SUPERAGENT && (
                          <p className="text-sm text-red-500">
                            * Please upload passport.
                          </p>
                        )}
                      </div>
                    )}

                    {passengerTypeState === "Foreigner" && (
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label> Other Uploads </Label>
                        </div>
                        <input
                          accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                          // style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          name="other"
                          onChange={(e) => handleAcceptedFiles(e, index)}
                          required={passengerTypeState === "Foreigner"}
                          // className="w-1/2"
                        />
                        <Label className="text-gray-700">
                          Upload a file with Max size of 5MB
                        </Label>
                        <p className="text-sm text-red-500">
                          * Please upload Yellow Card, Residential Permit, Work
                          Permit or Origin Id
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  };

  return (
    <div
      className={`nc-CheckOutPage ${className} px-1 py-4`}
      data-nc-id="CheckOutPage"
    >
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
          {/* <div className="">{renderMain()}</div> */}
          <form onSubmit={onSubmit}>
            {travelerTypes?.map((type, index) => {
              return (
                <div className="mb-10">
                  <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 shadow space-y-8 px-5 sm:p-6 xl:p-8 mb-2">
                    <div>
                      <h3 className="text-2xl font-semibold">
                        Passenger {index + 1} Information ({type})
                      </h3>
                      {/* {company &&
                        (company?.wallet < WALLET_MINIMUM_AMOUNT ||
                          company.wallet < parseFloat(totalPrice())) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            Insufficient balance in your wallet. Please deposit
                            money to continue.
                          </p>
                        )} */}
                      {enterprise &&
                        enterprise?.method === "prePaid" &&
                        enterprise?.wallet <
                          WALLET_MINIMUM_AMOUNT + parseFloat(totalPrice()) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            Insufficient balance in your wallet. Please deposit
                            money to continue.
                          </p>
                        )}
                      {user?.role === USER_ROLE_EMPLOYEE &&
                        user?.dailyBookingLimit <
                          parseFloat(totalPrice() + user?.dailyBooking) && (
                          <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                            <i className="fas fa-exclamation-triangle mr-2"></i>
                            You have reached your daily booking limit.
                          </p>
                        )}
                      {renderMain(index, type)}
                    </div>
                  </div>

                  <hr className="my-5" />
                </div>
              );
            })}
            <div className="flex justify-end pb-3">
              <ButtonPrimary
                className="hover:text-white hover:bg-blue-600 block w-72 md:w-48"
                type="submit"
              >
                Continue
              </ButtonPrimary>
            </div>
          </form>
        </div>
        <div className=" lg:block flex-grow mb-10 ">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default Booking;
