import axios from "axios";
import API_URL from "../../../redux2";

export const getNDCOrderRetrieve = async (retrieve, owner, setLoading) => {
  let header = {
    service: "OrderRetrieve",
    ThirdpartyId: owner,
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "ndc/order-retrieve", {
      Retrieve: retrieve,
      header,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const voidNDCTicket = async (data, owner, setLoading) => {
  let header = {
    service: "OrderCancel",
    ThirdpartyId: owner,
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "ndc/void-ticket", {
      Void: data,
      header,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const repriceNDCOrder = async (data, owner, setLoading) => {
  let header = {
    service: "OrderReshop",
    ThirdpartyId: owner,
    resource: "Reprice.V3",
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "ndc/reprice-order", {
      Reprice: data,
      header,
    });

    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const acceptNDCReprice = async (acceptReq, data, setLoading) => {
  let header = {
    service: "OrderChange",
    ThirdpartyId: data?.owner,
    resource: "AcceptRepricedOrder.V3",
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "ndc/accept-reprice", {
      AcceptReprice: acceptReq,
      header,
      data,
    });

    return res.data;
  } catch (error) {
    console.log(error);
    throw error?.response?.data;
  } finally {
    setLoading(false);
  }
};

export const requoteNDCOrder = async (data, owner, setLoading) => {
  let header = {
    service: "OrderReshop",
    ThirdpartyId: owner,
    resource: "Requote.V3",
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "ndc/requote-order", {
      Requote: data,
      header,
    });

    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const reshopNDCOrder = async (data, owner, setLoading) => {
  let header = {
    service: "ItinReshop",
    ThirdpartyId: owner,
    resource: "refundQuote",
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "ndc/reshop-order", {
      Reshop: data,
      header,
    });

    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const cancelPaidNDCOrder = async (data, cancelData, setLoading) => {
  let header = {
    service: "OrderCancel",
    ThirdpartyId: cancelData?.Owner,
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "ndc/cancel-paid-order", {
      Cancel: data,
      header,
      cancelData,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export const cancelNDCOrder = async (data, cancelData, setLoading) => {
  let header = {
    service: "OrderCancel",
    ThirdpartyId: cancelData?.carrier,
  };

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "ndc/cancel-order", {
      Cancel: data,
      header,
      cancelData,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};
