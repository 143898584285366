import { useLocation, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  currencyType,
  getCarrierInfo,
  getCarrierLogo,
} from "../../../utils/flightInfo";
import Loading from "../../Loading";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import NcImage from "../../NcImage/NcImage";

import ButtonPrimary from "../../Button/ButtonPrimary";
import { NDCCancelPaidOrderReq } from "../NDCRequest/NDCRequest";
import { cancelPaidNDCOrder } from "../Services/NDCApi";
import {
  REFUND_SC_AMOUNT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_SUPERAGENT,
  VOID_SC_AMOUNT,
} from "../../../common/constants";

const NDCReshop = ({ className = "" }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const { res, booking } = location.state;

  const { user } = useSelector((state) => state.auth);

  const { Order, ReShopOffers, DataLists, Passengers } = res;

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  if (loading) {
    return <Loading />;
  }

  const BasePrice = Number(
    Order?.TotalOrderPrice?.DetailCurrencyPrice?.Total.value
  );
  const passengerLength = Passengers?.Passenger.length;
  const itemQuantity =
    ReShopOffers?.ReShopOffer?.[0]?.Parameters?.TotalItemQuantity;
  const currency =
    ReShopOffers?.ReShopOffer?.[0]?.ReshopDifferential?.ReshopDue?.ByAirline
      ?.Total?.Amount?.Code ||
    Order?.TotalOrderPrice?.DetailCurrencyPrice?.Total?.Code;

  const getServiceCharge = () =>
    (booking?.serviceCharge || 0) + (booking?.NDCServiceCharge || 0);

  function totalPrice(price) {
    return convertNumbThousand(price + getServiceCharge()) + " " + currency;
  }

  function returnablePrice(price) {
    const base = isVoid() ? price + getServiceCharge() : price;
    return convertNumbThousand(base) + " " + currency;
  }

  function getPassengerByRef(ref) {
    return ReShopOffers?.ReShopOffer?.find(
      (offer) => offer?.OfferID?.value === ref
    )?.ReshopDifferential;
  }

  let refundAmount = 0;
  const parameters = ReShopOffers?.ReShopOffer?.[0]?.Parameters;

  const calculateRefundAmount = () => {
    if (parameters?.ApplyToAllInd) {
      return (
        ReShopOffers?.ReShopOffer?.[0]?.ReshopDifferential?.ReshopDue?.ByAirline
          ?.Total?.Amount?.value || 0
      );
    }

    if (passengerLength === itemQuantity) {
      return ReShopOffers?.ReShopOffer?.reduce(
        (acc, offer) =>
          acc +
          (offer?.ReshopDifferential?.ReshopDue?.ByAirline?.Total?.Amount
            ?.value || 0),
        0
      );
    }

    if (passengerLength > itemQuantity) {
      parameters?.PTC_Priced?.forEach((ptc) => {
        const passengerRef = ptc.refs[0];
        const reshop = getPassengerByRef(passengerRef);
        const reshopAmount =
          reshop?.ReshopDue?.ByAirline?.Total?.Amount?.value || 0;
        refundAmount += reshopAmount * (ptc?.Priced?.Quantity || 0);
      });
      return refundAmount;
    }

    return -1;
  };

  const calculatePenalty = () => {
    // const penaltyAmount = ReShopOffers?.ReShopOffer?.reduce((acc, offer) => {
    //   return (
    //     acc +
    //     (offer?.ReshopDifferential?.PenaltyAmount?.Total?.Amount?.value || 0)
    //   );
    // }, 0);

    // return penaltyAmount;
    let penaltyAmount = 0;
    if (parameters?.ApplyToAllInd) {
      return (
        ReShopOffers?.ReShopOffer?.[0]?.ReshopDifferential?.PenaltyAmount?.Total
          ?.Amount?.value || 0
      );
    }

    if (passengerLength === itemQuantity) {
      return ReShopOffers?.ReShopOffer?.reduce(
        (acc, offer) =>
          acc +
          (offer?.ReshopDifferential?.PenaltyAmount?.Total?.Amount?.value || 0),
        0
      );
    }

    if (passengerLength > itemQuantity) {
      parameters?.PTC_Priced?.forEach((ptc) => {
        const passengerRef = ptc.refs[0];
        const reshop = getPassengerByRef(passengerRef);
        const reshopAmount = reshop?.PenaltyAmount?.Total?.Amount?.value || 0;
        penaltyAmount += reshopAmount * (ptc?.Priced?.Quantity || 0);
      });
      return penaltyAmount;
    }

    return 0;
  };

  refundAmount = calculateRefundAmount();

  function isVoid() {
    return refundAmount === BasePrice;
  }

  const isSuper =
    [USER_ROLE_SUPERAGENT, USER_ROLE_EMPLOYEE].includes(user?.role) &&
    !booking?.company;

  const SC = isSuper ? 0 : isVoid() ? VOID_SC_AMOUNT : REFUND_SC_AMOUNT;
  const NDC_SC = isVoid() || isSuper ? 0 : booking?.NDCServiceCharge || 0;

  const confirmOrderCancel = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center w-[500px] p-10 bg-white shadow-lg text-black">
            <h1 className="text-2xl font-bold text-red-500 p-2">
              Confirm Order Cancel
            </h1>
            <p className="text-lg">
              Are you sure you want to{" "}
              <span className="font-bold">{isVoid() ? "Void" : "Refund"}</span>{" "}
              this order?
            </p>
            <button
              onClick={onClose}
              className="w-40 border-1 cursor-pointer text-black text-lg m-2.5 p-2.5 border-black"
            >
              No
            </button>
            <button
              onClick={() => {
                orderCancel();
                onClose();
              }}
              className="w-40 border-1 cursor-pointer text-red-500 text-lg m-2.5 p-2.5 border-red-500 font-bold"
            >
              Yes, Proceed
            </button>
          </div>
        );
      },
    });
  };

  const orderCancel = async () => {
    if (refundAmount < 0) {
      return;
    }

    const correlationID =
      res?.CorrelationID ?? res?.ReShoppingResponseID?.ResponseID?.value;

    const data = {
      value: Order?.OrderID?.value,
      Owner: Order?.OrderID?.Owner,
      refundAmount,
      currency,
      metadata: res?.Metadata,
      correlationID,
      penalty: +calculatePenalty() ?? 0,
      type: isVoid() ? "Void" : "Refund",
      userId: user?._id,
      SC,
    };

    const cancelReq = NDCCancelPaidOrderReq(data);

    if (cancelReq) {
      const res = await cancelPaidNDCOrder(cancelReq, data, setLoading);

      if (res.Success && res.Response) {
        toast.success(`Order ${isVoid() ? "Voided" : "Refunded"} Successfully`);
        navigate("/booking");
        return;
      }

      if (res?.Errors) {
        setError(
          res?.Errors?.Error?.[0]?.Description?.value ??
            res?.Errors?.Error?.[0]?.value
        );
        return;
      }
    }
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 shadow space-y-6 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <NcImage src={getCarrierLogo(Order?.OrderID?.Owner)} />
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <span className="text-lg text-neutral-500 font-semibold">
              <h1>
                {getCarrierInfo(Order?.OrderID?.Owner)?.Name ||
                  Order?.OrderID?.Owner}
              </h1>
            </span>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Paid Price Detail</h3>

          <div className="flex justify-between text-neutral-600">
            <span>Sterling SC </span>
            <span>
              {booking?.serviceCharge || 0} {currencyType(currency)}
            </span>
          </div>
          <div className="flex justify-between text-neutral-600">
            <span>System Fare </span>
            <span>
              {booking?.flight?.price + (booking?.NDCServiceCharge || 0)}{" "}
              {currencyType(currency)}
            </span>
          </div>

          <div className="border-b border-neutral-200 "></div>

          <div className="flex justify-between font-medium text-xl text-black">
            <span>Total Price</span>
            <span className="">{totalPrice(BasePrice)}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 space-y-4 px-5 sm:p-6 xl:p-6 shadow">
        <div className="space-y-4">
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800">
            Order Cancel (Void / Refund)
          </h2>
          <div className="p-4 rounded-lg bg-gray-50 text-base ">
            <div className="flex items-center justify-start gap-2 text-green-600">
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              {DataLists?.DisclosureList ? (
                <p className="">
                  {
                    DataLists?.DisclosureList?.Disclosures?.[0]
                      ?.Description?.[0]?.Text?.value
                  }
                </p>
              ) : (
                <p className="">Eligible To Refund Ticket</p>
              )}
            </div>
          </div>
          <div
            className="mt-6 flex flex-col 
          w-full sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200"
          >
            <div className="px-1 py-4  text-lg font-light">
              {Passengers?.Passenger.map((travel, index) => (
                <Fragment key={index}>
                  <h1 className="text-xl font-bold p-2">Passenger {++index}</h1>
                  <div className="flex flex-row justify-between px-4 space-x-12">
                    <h1 className="">Name</h1>
                    <span className="text-neutral-500 dark:text-neutral-400 ">
                      {travel?.Name?.Given?.[0]?.value}{" "}
                      {travel?.Name?.Surname?.value}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between  px-4 py-2">
                    <h1 className="">PNR</h1>
                    <span className="text-neutral-500 dark:text-neutral-400 ">
                      <span>{Order.OrderID?.value}</span>
                    </span>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
          <div className="flex flex-col space-y-4 font-normal text-lg text-black mb-4 mx-2">
            <div className="flex justify-between">
              <span>Cancel Type</span>
              <span>{isVoid() ? "Void" : "Refund"}</span>
            </div>

            <div className="flex justify-between">
              <span>Service Charge </span>
              <span>
                {SC} {currencyType(currency)}
              </span>
            </div>
            <div className="flex justify-between">
              <span>Penalty </span>
              <span>
                {convertNumbThousand(calculatePenalty() + NDC_SC)} {currency}
              </span>
            </div>

            <div className="flex justify-between text-xl text-black">
              <span>Return Price</span>
              <span className="text-main font-semibold">
                {returnablePrice(refundAmount - SC)}
              </span>
            </div>
          </div>
        </div>

        <div className="row lg:p-0 space-y-3  ">
          <div className="col-12">
            <ButtonPrimary
              className="bg-main py-3 hover:bg-main-800 w-full"
              onClick={confirmOrderCancel}
              disabled={refundAmount < 0}
            >
              Proceed
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CheckOutPage ${className} px-1 py-4`}
      data-nc-id="CheckOutPage"
    >
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className=" lg:block flex-grow mb-10">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default NDCReshop;
