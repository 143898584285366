import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MainContainer from "./layouts/Main.container";
import Billing from "./Pages/Billing";
import Dashboard from "./Pages/Dashboard";
import FindFlight from "./Pages/Home";
import Members from "./Pages/Members";
import Trips from "./Pages/Trips";
import Login from "./Pages/Auth/Login/Login";
import ProfilePage from "./Pages/Profile";
import Footer from "./layouts/Footer";
import RecentFlights from "./Pages/Home/RecentFlights";
import Agent from "./Pages/Agents";
import AgentTrends from "./Pages/Agents/AgentTrends";
import AgentBooking from "./Pages/Agents/AgentBooking";
import AgentBilling from "./Pages/Agents/AgentBilling";
import Credit from "./Pages/Credit/index";
import BillingAgent from "./Pages/Billing/BillingAgent";
import FlightDetail from "./components/Flights/FlightDetail.jsx";
import FlightDetail2 from "./components/Flights/FlightDetail2";
import SearchFlightMobile from "./layouts/nav/mobile/SearchFlightMobile";
import CustomPrintTicketPage from "./components/Ticket/CustomPrint";
import ContactForm from "./components/Contact/Contact";
import ResetPassword from "./components/User/ResetPassword";
import CheckOutPage from "./components/FlightBookingSteps/CheckOutPage";
import CustomerDetail from "./components/FlightBookingSteps/CustomerDetail";
import PayPage from "./components/FlightBookingSteps/done";

import Error404 from "./Pages/Error/Error404";
import { useDispatch } from "react-redux";
import { logout } from "./redux2/user/authSlice";
import SendRequest from "./components/Request/SendRequest";
import RequestPage from "./Pages/Request";
import RequestDetail from "./Pages/Request/RequestDetail";
import BillingEnterprise from "./Pages/Billing/BillingEnterprise";
import VoidCheck from "./components/VoidCheck";
// NDC
import Shopping from "./components/NDC/AirShopping/Shopping.jsx";
import Pricing from "./components/NDC/AirPricing/Pricing.jsx";
import Booking from "./components/NDC/AirBooking/Booking.jsx";
import DonePage from "./components/NDC/AirBooking/Done.jsx";
import PayLater from "./components/NDC/AirPayment/PayLater.jsx";
import NDCInstantPayment from "./components/NDC/AirPayment/InstantPayment.jsx";
import NDCTicketPrint from "./components/NDC/Ticket/NDCTicketPrint.jsx";
import NDCCheckStatus from "./components/NDC/CheckStatus/index.jsx";
// Exchange
import Exchange from "./components/Exchange/index";
import Eligibility from "./components/Exchange/Eligibility.jsx";
import ExchangeSearch from "./components/Exchange/ExchangeSearch.jsx";
import ModifyOffer from "./components/Exchange/ModifyOffer.jsx";
// import FlightResult from "./Pages/Flight/FlightResult";
import ExchangePayment from "./components/Exchange/ExchangePayment.jsx";
import NDCReshop from "./components/NDC/AirCancel/Reshop.jsx";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const expirationTime = localStorage.getItem("expirationTime");
    const now = new Date().getTime();

    if (now > expirationTime) {
      dispatch(logout());
    }
  }, []);

  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(logout, 1800000); // 30 minutes
    };

    // 2 minutes = 120000
    // 5 minutes = 300000
    // 10 minutes = 600000
    // 15 minutes = 900000
    // 20 minutes = 1200000
    // 25 minutes = 1500000
    // 30 minutes = 1800000

    const logout = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href = "/login";
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("mousedown", resetTimer);

    resetTimer();

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("mousedown", resetTimer);
    };
  }, []);

  return (
    <>
      <Routes>
        {/* <Route path="/signup" element={<Register />} /> */}
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          index
          element={
            <>
              <MainContainer />
              <div className="lg:hidden w-full my-2 mx-auto">
                <SearchFlightMobile />
              </div>
              <FindFlight />
              <Footer />
            </>
          }
        />

        <Route
          path="/contact"
          element={
            <>
              <MainContainer />
              <ContactForm />
              <Footer />
            </>
          }
        />

        {/* <Route 
          path="/flight-result"
          element={
            <>
              <MainContainer />
              <FlightResult />
            </>
          }
         /> */}

        <Route
          path="/flight-detail"
          element={
            <>
              <MainContainer />
              <FlightDetail />
            </>
          }
        />
        <Route
          path="/flight-detail2"
          element={
            <>
              <MainContainer />
              <FlightDetail2 />
            </>
          }
        />
        <Route
          path="/search-result"
          element={
            <>
              <MainContainer />
              <RecentFlights />
            </>
          }
        />
        {/* <Route
          path="/search-card"
          element={
            <>
              <MainContainer />
              <RecentFlights />
            </>
          }
        /> */}
        <Route
          path="/customer-detail"
          element={
            <>
              <MainContainer />
              <CustomerDetail />
            </>
          }
        />
        <Route
          path="/checkout/:id"
          element={
            <>
              <MainContainer />
              <CheckOutPage />
            </>
          }
        />
        <Route
          path="pay-done/:id"
          element={
            <>
              <MainContainer />
              <PayPage />
            </>
          }
        />
        <Route
          path="/billing"
          element={
            <>
              <MainContainer />
              <Billing />
            </>
          }
        />
        <Route
          path="/booking"
          element={
            <>
              <MainContainer />
              <Trips />
            </>
          }
        />
        <Route
          path="/members"
          element={
            <>
              <MainContainer />
              <Members />
            </>
          }
        />
        <Route
          path="/agents"
          element={
            <>
              <MainContainer />
              <Agent />
            </>
          }
        />
        <Route
          path="/agent-trends"
          element={
            <MainContainer>
              <AgentTrends />
            </MainContainer>
          }
        />
        <Route
          path="/flight-list/:id"
          element={
            <MainContainer>
              <AgentBooking />
            </MainContainer>
          }
        />
        <Route
          path="/agent-billing/:id"
          element={
            <>
              <MainContainer />
              <AgentBilling />
            </>
          }
        />
        <Route
          path="/billing-agent"
          element={
            <>
              <MainContainer />
              <BillingAgent />
            </>
          }
        />
        <Route
          path="/billing-enterprise"
          element={
            <>
              <MainContainer />
              <BillingEnterprise />
            </>
          }
        />
        <Route
          path="/credit"
          element={
            <>
              <MainContainer />
              <Credit />
              <Footer />
            </>
          }
        />
        <Route
          path="/dashboard"
          element={
            <>
              <MainContainer />
              <Dashboard />
              <Footer />
            </>
          }
        />
        <Route
          path="/profile"
          element={
            <MainContainer>
              <ProfilePage />
            </MainContainer>
          }
        />
        <Route
          path="/requests"
          element={
            <>
              <MainContainer />
              <RequestPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/requests/detail/:id"
          element={
            <>
              <MainContainer />
              <RequestDetail />
              <Footer />
            </>
          }
        />
        <Route
          path="/send-request/:id"
          element={
            <>
              <MainContainer />
              <SendRequest />
              <Footer />
            </>
          }
        />
        <Route
          path="/ticket/:id"
          element={
            <>
              <MainContainer />
              <CustomPrintTicketPage />
              <Footer />
            </>
          }
        />
        <Route
          path="/check-ticket/:pnr"
          element={
            <>
              <MainContainer />
              <VoidCheck />
              <Footer />
            </>
          }
        />

        {/* user routes */}
        <Route
          path="/reset-password"
          element={
            <>
              <ResetPassword />
              <Footer />
            </>
          }
        />

        {/* NDC */}
        <Route
          path="/shopping"
          element={
            <>
              <Shopping />
              <Footer />
            </>
          }
        />
        <Route
          path="/ndc-pricing"
          element={
            <>
              <MainContainer />
              <Pricing />
              <Footer />
            </>
          }
        />
        <Route
          path="/ndc-booking"
          element={
            <>
              <MainContainer />
              <Booking />
              <Footer />
            </>
          }
        />
        <Route
          path="/ndc-done"
          element={
            <>
              <MainContainer />
              <DonePage />
              <Footer />
            </>
          }
        />
        <Route
          path="/ndc-instant-payment"
          element={
            <>
              <MainContainer />
              <NDCInstantPayment />
              <Footer />
            </>
          }
        />
        <Route
          path="/ndc-pay-later/:pnr"
          element={
            <>
              <MainContainer />
              <PayLater />
              <Footer />
            </>
          }
        />
        <Route
          path="/ndc-cancel/:pnr"
          element={
            <>
              <MainContainer />
              <NDCReshop />
              <Footer />
            </>
          }
        />
        <Route
          path="/ndc-ticket/:id"
          element={
            <>
              <MainContainer />
              <NDCTicketPrint />
              <Footer />
            </>
          }
        />
        <Route
          path="/ndc-check-status/:pnr"
          element={
            <>
              <MainContainer />
              <NDCCheckStatus />
              <Footer />
            </>
          }
        />

        {/* exchange routes */}
        <Route
          path="/exchange"
          element={
            <>
              <MainContainer />
              <Exchange />
              <Footer />
            </>
          }
        />

        <Route
          path="/exchange-eligibility"
          element={
            <>
              <MainContainer />
              <Eligibility />
              <Footer />
            </>
          }
        />
        <Route
          path="/exchange-search"
          element={
            <>
              <MainContainer />
              <ExchangeSearch />
              <Footer />
            </>
          }
        />
        <Route
          path="/exchange-offer"
          element={
            <>
              <MainContainer />
              <ModifyOffer />
              <Footer />
            </>
          }
        />
        <Route
          path="/exchange-payment"
          element={
            <>
              <MainContainer />
              <ExchangePayment />
              <Footer />
            </>
          }
        />

        <Route
          path="*"
          element={
            <>
              <Error404 />
              <Footer />
            </>
          }
        />
        {/* <Route path="*" element={<p>There's nothing here: 404!</p>} /> */}
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
