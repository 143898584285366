import { NDC } from "../../../common/constants";

export const NDCRetrieveReq = (data) => ({
  Query: {
    Filters: {
      OrderID: {
        value: data?.PNR || data.value,
        Owner: data?.carrier || data.Owner,
        Channel: NDC,
      },
    },
  },
});

export const NDCCancelOrderReq = (data) => ({
  Query: {
    OrderID: [
      {
        value: data?.PNR || data.value,
        Owner: data?.carrier || data.Owner,
        Channel: NDC,
      },
    ],
  },
});

export const NDCCancelPaidOrderReq = (data) => ({
  Query: {
    OrderID: [
      {
        value: data?.PNR || data.value,
        Owner: data?.carrier || data.Owner,
        Channel: NDC,
      },
    ],
  },
  ExpectedRefundAmount: {
    Total: {
      value: data?.refundAmount,
      Code: data?.currency,
    },
  },
  Metadata: data?.metadata,
  ...(data?.correlationID ? { CorrelationID: data?.correlationID } : {}),
});

export const NDCRepriceReq = (data) => ({
  orderId: data?.PNR || data.value,
  ownerCode: data?.carrier || data.Owner,
  channel: NDC,
});

export const NDCAcceptOrderReq = (data, booking) => ({
  ownerCode: booking?.carrier,
  orderId: booking?.PNR,
  channel: NDC,
  acceptRepricedOrder: {
    dataMap: data?.dataMap,
    paymentFunctions: [
      {
        paymentProcessingDetails: {
          typeCode: "CA",
          amount: {
            ...data?.repriceResult?.totalPrice?.totalAmount,
            nanos: 0,
          },
        },
      },
    ],
    augmentations: data?.augmentations,
  },
});

export const NDCRequoteReq = (data) => ({
  orderId: data?.PNR || data.value,
  ownerCode: data?.carrier || data.Owner,
  channel: NDC,
});

export const NDCReshopReq = (data) => ({
  Query: {
    Reshop: {
      Actions: [
        {
          ActionType: {
            value: "Cancel",
          },
          OrderItems: {
            OrderItem: data?.orderItems?.map((item) => ({
              OrderItemID: {
                value: item,
                Owner: data?.carrier || data.Owner,
              },
            })),
          },
          OrderID: {
            Owner: data?.carrier || data.Owner,
            Channel: NDC,
            value: data?.PNR || data.value,
          },
        },
      ],
    },
  },
});
