import React, { Fragment } from "react";
import { Tab } from "@headlessui/react";

import {
  ONE_WEEK_CREDIT,
  TWO_WEEK_CREDIT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_SUPERAGENT,
  WALLET_MINIMUM_AMOUNT,
} from "../../common/constants";
import { currencyType } from "../../utils/flightInfo";
// import abyssinia from "../../images/banks/aby.png";
import wallet from "../../images/banks/wallet.png";
import awash from "../../images/banks/awash-bank.png";
import credit from "../../images/banks/credit.png";
import cbe from "../../images/banks/cbe-bank.png";
import amhara from "../../images/banks/amhara-bank2.png";
import kacha from "../../images/banks/kacha.png";

const PaymentMethodsList = ({
  paymentMethod,
  setPaymentMethod,
  setSelectedCorporate,
  user,
  company,
  totalPrice,
  enterprise,
  showCorporateList,
  setShowCorporateList,
  departureDate,
  passengerTypeState,
  currency,
  diffDate,
}) => {
  // return (
  //   <div className="mt-6">
  //     <Tab.Group>
  //       <Tab.List className="flex flex-wrap my-5 ">
  //         {paymentMethods.map((method) => {
  //           if (
  //             method.name === "wallet" ||
  //             !company ||
  //             company.wallet > WALLET_MINIMUM_AMOUNT ||
  //             (method.name === "credit" &&
  //               (user?.role === USER_ROLE_SUPERAGENT ||
  //                 user?.role === USER_ROLE_EMPLOYEE))
  //           ) {
  //             return (
  //               <Tab as={Fragment}>
  //                 {({ selected }) => (
  //                   <button
  //                     onClick={() => {
  //                       setPaymentMethod(method.name);
  //                       setSelectedCorporate(null);
  //                       console.log(selected);
  //                     }}
  //                     className={`p-8 rounded-lg focus:outline-none mr-10  ${
  //                       selected
  //                         ? "bg-neutral-800  text-white  h-36 pt-4"
  //                         : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
  //                     }`}
  //                   >
  //                     <img
  //                       src={method.icon}
  //                       alt=""
  //                       className="w-16 my-2 hidden md:block"
  //                     />
  //                     {method.title}
  //                   </button>
  //                 )}
  //               </Tab>
  //             );
  //           }
  //         })}
  //       </Tab.List>
  //     </Tab.Group>
  //   </div>
  // );

  const currentDate = new Date().toLocaleDateString();
  departureDate = new Date(departureDate).toLocaleDateString();

  const isSuper =
    user?.role === USER_ROLE_SUPERAGENT || user?.role === USER_ROLE_EMPLOYEE;

  function creditPass() {
    if (
      company &&
      company?.creditType !== "" &&
      company?.creditLimit > totalPrice &&
      currentDate !== departureDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  function USDCreditPass() {
    if (
      company &&
      company?.USDCreditType !== "" &&
      company?.USDCreditLimit > totalPrice &&
      currentDate !== departureDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  function checkDueDate() {
    if (company?.creditType === ONE_WEEK_CREDIT) {
      return diffDate > 7;
    } else if (company?.creditType === TWO_WEEK_CREDIT) {
      return diffDate > 14;
    }
  }

  return (
    <div className="mt-6">
      <Tab.Group>
        {/* {passengerTypeState === NON_DOCUMENT_HOLDER ? ( */}
        {currencyType(currency) === "USD" ? (
          <Tab.List className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-3">
            {USDCreditPass() ? (
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    onClick={() => {
                      setPaymentMethod("USDCredit");
                      setSelectedCorporate(null);
                    }}
                    className={`p-8 rounded-lg focus:outline-none mr-10  ${
                      paymentMethod === "USDCredit"
                        ? "bg-neutral-800  text-white  h-36 pt-4"
                        : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
                    }`}
                  >
                    <div className="flex flex-col items-center">
                      <img src={credit} alt="" className="w-20 my-2" />
                      USD Credit
                    </div>
                  </button>
                )}
              </Tab>
            ) : (
              <div className="w-full">
                <p>No payment method available.</p>
              </div>
            )}
          </Tab.List>
        ) : (
          <Tab.List className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-3">
            {enterprise?.method !== "postPaid" &&
              (!company ||
                company.wallet > WALLET_MINIMUM_AMOUNT + totalPrice) && (
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      onClick={() => {
                        setPaymentMethod("wallet");
                        setSelectedCorporate(null);
                      }}
                      className={`p-8 rounded-lg focus:outline-none mr-10  ${
                        paymentMethod === "wallet"
                          ? "bg-neutral-800  text-white  h-36 pt-4"
                          : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
                      }`}
                    >
                      <div className="flex flex-col items-center gap-2">
                        <img src={wallet} alt="" className="w-16 my-2" />
                        Wallet
                      </div>
                    </button>
                  )}
                </Tab>
              )}

            {(user?.role === USER_ROLE_SUPERAGENT ||
              user?.role === USER_ROLE_EMPLOYEE) && (
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    onClick={() => {
                      setPaymentMethod("credit");
                      setShowCorporateList(!showCorporateList);
                    }}
                    className={`p-8 rounded-lg focus:outline-none mr-10  ${
                      paymentMethod === "credit"
                        ? "bg-neutral-800  text-white  h-36 pt-4"
                        : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
                    }`}
                  >
                    <div className="flex flex-col items-center">
                      <img
                        src={credit}
                        alt=""
                        className="w-16 my-2"
                        // className="w-16 my-2 hidden md:block"
                      />
                      Credit
                    </div>
                  </button>
                )}
              </Tab>
            )}

            {/* hidden xl:block */}
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => {
                    setPaymentMethod("webirr_cbe");
                  }}
                  className={`px-8 py-0 rounded-lg focus:outline-none mr-10
             ${
               paymentMethod === "webirr_cbe"
                 ? "bg-neutral-800  text-white  h-36 pt-4"
                 : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
             }
            `}
                >
                  <div className="flex flex-col items-center gap-2">
                    <img src={cbe} alt="" className="w-16 my-2" />
                    CBE
                  </div>
                </button>
              )}
            </Tab>
            {/* <Tab as={Fragment}>
        {({ selected }) => (
          <button
            onClick={() => {
              setPaymentMethod("cbeBirr");
            }}
            className={`p-8 rounded-lg focus:outline-none mr-10
             ${
               selected
                 ? "bg-neutral-800  text-white  h-36 pt-4"
                 : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
             }
            `}
          >
            <img
              src="https://ethiopianlogos.com/logos/cbe_birr_light/cbe_birr_light.svg"
              alt=""
              className="w-16 my-2"
            />
            CBE Birr
          </button>
        )}
      </Tab> */}
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => {
                    setPaymentMethod("webirr_awash");
                  }}
                  className={`p-8 rounded-lg focus:outline-none mr-10  ${
                    paymentMethod === "webirr_awash"
                      ? "bg-neutral-800  text-white  h-36 pt-4"
                      : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
                  }`}
                >
                  <div className="flex flex-col items-center gap-2">
                    <img src={awash} alt="" className="w-16 my-2" />
                    Awash Bank
                  </div>
                </button>
              )}
            </Tab>
            {/* <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => {
                    setPaymentMethod("webirr_abyssinia");
                  }}
                  className={`p-8 rounded-lg focus:outline-none mr-10  ${
                    paymentMethod === "webirr_abyssinia"
                      ? "bg-neutral-800  text-white  h-36 pt-4"
                      : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
                  }`}
                >
                  <div className="flex flex-col items-center gap-2">
                    <img src={abyssinia} alt="" className="w-16 my-2" />
                    <p className="truncate"> Abyssinia Bank</p>
                  </div>
                </button>
              )}
            </Tab> */}
            <Tab as={Fragment}>
              {({ selected }) => (
                <button
                  onClick={() => {
                    setPaymentMethod("amhara");
                  }}
                  className={`p-8 rounded-lg focus:outline-none mr-10  ${
                    paymentMethod === "amhara"
                      ? "bg-neutral-800  text-white  h-36 pt-4"
                      : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
                  }`}
                >
                  <div className="flex flex-col items-center gap-2">
                    <img src={amhara} alt="" className="w-16 my-2" />
                    <p className="truncate">Amhara Bank</p>
                  </div>
                </button>
              )}
            </Tab>
            {creditPass() && !isSuper && (
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    onClick={() => {
                      setPaymentMethod("credit");
                      setSelectedCorporate(null);
                    }}
                    disabled={checkDueDate()}
                    className={`p-8 rounded-lg focus:outline-none mr-10  ${
                      paymentMethod === "credit"
                        ? "bg-neutral-800  text-white  h-36 pt-4"
                        : "text-neutral-6000  border border-gray-800 shadow-xl h-36 pt-4"
                    }`}
                  >
                    <div className="flex flex-col items-center">
                      <img
                        src="https://media.istockphoto.com/vectors/safe-payment-logo-template-designs-vector-illustration-vector-id1215256045?k=20&m=1215256045&s=612x612&w=0&h=IQX0a8uPVKSTGfJyWtWv3eXAMk4LTuQSmMtNqv_IBZs="
                        alt=""
                        className="w-20 my-2"
                      />
                      Credit
                    </div>
                  </button>
                )}
              </Tab>
            )}
            {/* {company?.kachaCredit && (
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    onClick={() => {
                      setPaymentMethod("kacha");
                      setSelectedCorporate(null);
                    }}
                    disabled={checkDueDate()}
                    className={`p-6 rounded-lg focus:outline-none mr-10  ${
                      paymentMethod === "kacha"
                        ? "bg-neutral-800 text-white h-36 pt-4"
                        : "text-neutral-6000 border border-gray-800 shadow-xl h-36 pt-4"
                    }`}
                  >
                    <div className="flex flex-col items-center ">
                      <img src={kacha} alt="" className="w-20 mb-1" />
                      <p className="">Kacha Credit</p>
                    </div>
                  </button>
                )}
              </Tab>
            )} */}
          </Tab.List>
        )}
      </Tab.Group>
      <div className="text-yellow-500 px-4 mt-5">
        {/* {company &&
          paymentMethod === "wallet" &&
          company?.wallet < WALLET_MINIMUM_AMOUNT + totalPrice && (
            <h1 className="text-red-500 text-sm border border-red-500 rounded-md py-2 my-2">
              * You have reached your Wallet Balance please deposit to continue
              first.
            </h1>
          )} */}

        {!creditPass() && !isSuper && (
          <div className="text-red-500 text-sm border border-red-500 rounded-md py-2 my-2">
            {currentDate === departureDate ? (
              <span>
                * You can't use Credit Limit for today's flight. Please select
                another payment method.
              </span>
            ) : (
              <span>
                * Today's sales are about to exceed your credit limit. Please
                pay the outstanding balance and continue purchasing tickets
                using your daily credit allowance
              </span>
            )}
          </div>
        )}
        {checkDueDate() && (
          <div className="text-red-500 text-sm border border-red-500 rounded-md py-2 my-2">
            * Your credit limit has expired. Please pay the outstanding balance
            and continue purchasing tickets using your credit allowance.
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentMethodsList;
