import React from "react";
import { useLocation } from "react-router-dom";

const NDCCheckStatus = () => {
  const location = useLocation();
  let { ticketData } = location.state;

  const {
    TicketDocInfos: { TicketDocInfo } = [],
    Passengers: { Passenger } = {},
  } = ticketData;

  const getPassengerByRef = (ref) => {
    return Passenger.filter((passenger) => passenger.ObjectKey === ref)?.[0]
      ?.Name;
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row items-center">
        <div className="mx-4 sm:mx-20 py-4 mt-4">
          <div className="flex flex-col p-2 space-y-3 text-black w-60 ml-4 sm:ml-20 border border-black rounded-lg">
            <h1 className="font-semibold text-lg mb-1">Legends</h1>
            {[
              { code: "T/O/I/OPE", description: "Ticketed" },
              { code: "AL", description: "Airport Control" },
              { code: "V", description: "Void" },
              { code: "R/RF", description: "Refunded" },
              { code: "X", description: "Exchanged" },
              { code: "P", description: "Partially Refunded" },
            ].map((legend, index) => (
              <div key={index} className="flex items-center mt-2">
                <p className="ml-2">
                  {legend.code} - {legend.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="md:mx-10 w-full">
          <div className="flex flex-row justify-between">
            <h2 className="text-2xl font-semibold text-black p-2">
              Ticket Status Checker
            </h2>
            <div>
              <button
                className="bg-red-500 hover:bg-red-600 rounded-lg px-4 py-2 text-white font-bold"
                onClick={() => window.history.back()}
              >
                Go Back
              </button>
            </div>
          </div>
          <div className="table-responsive">
            <table
              className="table card-table default-table display mb-4 dataTablesCard dataTable no-footer text-gray-700"
              id="example5"
              role="grid"
              aria-describedby="example5_info"
            >
              <thead>
                <tr role="row">
                  <th className="sorting_asc bg-none">
                    <p>#</p>
                  </th>
                  <th className="sorting">Passenger Name</th>
                  <th className="sorting">Status</th>
                </tr>
              </thead>
              <tbody>
                {TicketDocInfo?.map((passenger, index) => {
                  const passengerName = getPassengerByRef(
                    passenger?.PassengerReference?.[0]
                  );
                  return (
                    <tr role="row" key={index}>
                      <td>
                        <div className="flex flex-row gap-2">
                          <div>{index + 1}</div>
                        </div>
                      </td>
                      <td>
                        <div className="text-black font-semibold capitalize text-sm">
                          {passengerName?.Given?.[0]?.value}{" "}
                          {passengerName?.Surname?.value}
                        </div>
                      </td>
                      <td>
                        <div className="flex gap-2">
                          {passenger?.TicketDocument?.flatMap((ticket) =>
                            ticket.CouponInfo.map((coupon) => (
                              <span className="badge badge-success text-white px-2 py-1 rounded-full text-xs font-semibold bg-green-500 text-center">
                                {coupon.Status?.Code}
                              </span>
                            ))
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NDCCheckStatus;
