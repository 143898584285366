import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";

import { getBookingById } from "../../../redux2/booking/bookingSlice";
import { getMyCompany } from "../../../redux2/user/userSlice";
import {
  DIRECT_PAYMENT,
  USER_ROLE_ENTERPRISE,
  WALLET_MINIMUM_AMOUNT,
} from "../../../common/constants";
import { getMyEnterprise } from "../../../redux2/enterprise/enterpriseSlice";
import { currencyType, getCarrierLogo } from "../../../utils/flightInfo";
import API_URL from "../../../redux2";
import {
  getLoanProduct,
  initiateLoanRequest,
  requestLoan,
} from "../../../services/payment";
import { openModal } from "../../../redux2/modal/modalSlice";
import Loading from "../../Loading";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import NcImage from "../../NcImage/NcImage";
import PaymentMethodsList from "../../PaymentMethods/PaymentMethodsList";
import { paymentMethods } from "../../../utils/paymentMethods";
import ButtonPrimary from "../../Button/ButtonPrimary";
import KachaCreditOTP from "../../FlightBookingSteps/KachaCreditOTP";
import CorporateList from "../../FlightBookingSteps/CorporateList";
import { NDCAcceptOrderReq } from "../NDCRequest/NDCRequest";
import { acceptNDCReprice } from "../Services/NDCApi";

const NDCPayLater = ({ className = "" }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("wallet");
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [showCorporateList, setShowCorporateList] = useState(false);
  const [payment, setPayment] = useState(null);
  const [kachaInfo, setKachaInfo] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const passengerTypeState = query.get("passengerTypeState");

  const { retrieve, reprice, booking } = location.state;

  const { company } = useSelector((state) => state.user);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { user } = useSelector((state) => state.auth);

  const { id } = useParams();

  // const currency = "ETB";
  const currency =
    retrieve?.Order?.[0]?.TotalOrderPrice?.SimpleCurrencyPrice?.Code;

  useEffect(() => {
    dispatch(getBookingById(id));
    dispatch(getMyCompany());
  }, [dispatch, id]);

  useEffect(() => {
    if (user?.role === USER_ROLE_ENTERPRISE) {
      dispatch(getMyEnterprise());
      setPaymentMethod("webirr_cbe");
    }

    if (
      booking?.company &&
      currencyType(currency) === "USD" &&
      company?.USDCreditLimit > totalPrice
    ) {
      setPaymentMethod("USDCredit");
    } else {
      setPaymentMethod("");
    }

    if (
      booking?.company &&
      booking?.company.wallet <
        WALLET_MINIMUM_AMOUNT + priceToNumber(totalPrice()) &&
      currencyType(currency) === "USD"
      // passengerTypeState !== NON_DOCUMENT_HOLDER
    ) {
      setPaymentMethod("webirr_cbe");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.role, company]);

  const currentDate = new Date();

  const handleClick = async (otp) => {
    if (!paymentMethod) return toast.error("Please select payment method");

    // e.preventDefault();
    if (
      booking?.company &&
      paymentMethod === "wallet" &&
      booking?.company.wallet <
        WALLET_MINIMUM_AMOUNT + priceToNumber(totalPrice())
    ) {
      toast.error(
        `You can't use wallet for payment. You don't have enough balance. Please use other payment method.`
      );
      return;
    }

    if (!booking?.company && paymentMethod === "credit") {
      if (!selectedCorporate) {
        toast.error("Please select corporate");
        return;
      }
    }

    if (!reprice || !booking?.flight || !user) {
      return;
    }

    const acceptReq = NDCAcceptOrderReq(reprice, booking?.flight);

    const data = {
      samePrice: reprice?.repriceResult?.noPriceChangeInd || false,
      newPrice: reprice?.repriceResult?.totalPrice?.totalAmount,
      newTotalPrice:
        Number(reprice?.repriceResult?.totalPrice?.totalAmount.units) +
        booking?.serviceCharge,
      payment: paymentMethod,
      otp,
      bookingId: booking?._id,
      owner: booking?.flight?.carrier,
      userId: user?._id,
      companyId: booking?.company?._id,
      issuedAt: moment(currentDate).format("YYYY-MM-DDTHH:mm:ss"),
    };

    if (data?.newTotalPrice < 1) {
      return;
    }

    const res = await acceptNDCReprice(acceptReq, data, setLoading);
    console.log(res);

    if (res.Response) {
      toast.success("Booking Successful");
      navigate("/booking");
      return;
    }

    if (res?.errors) {
      setError(res?.errors?.Error?.[0]);
      return;
    }
  };

  const handleKachaSubmit = async (otp) => {
    handleClick(otp);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  let issueAt = moment(currentDate).format("YYYY-MM-DDTHH:mm:ss");

  const getTicket = (otp) =>
    new Promise((resolve, reject) => {
      let data = {
        id,
        PNR: retrieve.Order[0].OrderID?.value,
        payment: paymentMethod || "wallet",
        otp: otp || null,
        reference: kachaInfo?.reference || null,
        corporateName: selectedCorporate || null,
        userId: user?._id,
        companyId: company?._id,
        issuedAt: issueAt,
      };

      axios
        .post(API_URL + "flight/ticket", data)
        .then((res) => {
          resolve(res.data?.AirTicketingRsp);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });

  const initiatePayment = async () => {
    if (paymentMethod === "kacha") {
      let totalPrincipal = parseFloat(booking?.totalPrice);

      const kachaReq = {
        phone: company?.officeNumber,
        // principal: totalPrincipal.toString(),
        principal: "100",
        // phone: "251923130324",
        code: "80000030",
        pnr_number: retrieve.Order[0].OrderID?.value,
      };

      try {
        setLoading(true);
        const [data, product] = await Promise.all([
          requestLoan(kachaReq),
          getLoanProduct(kachaReq.phone),
        ]);

        if (data?.initiate) {
          const modalData = {
            kacha: data?.initiate,
            id: booking?._id,
            issueTicket: true,
            product,
          };
          let modalType = "Kacha";
          setKachaInfo(data.initiate);

          dispatch(openModal({ modalType, data: modalData }));
        }
      } catch (err) {
        setError(err.message || "Something went wrong");
      } finally {
        setLoading(false);
      }

      return;
    }
    const data = {
      pnr: retrieve.Order[0].OrderID?.value,
      paymentMethod,
    };

    await initiateLoanRequest(data, setLoading, setPayment);
  };

  const flightSegment = retrieve?.Order[0]?.OrderItems?.OrderItem?.reduce(
    (segments, item) => {
      if (item?.FlightItem?.OriginDestination && segments.length === 0) {
        segments.push(item.FlightItem.OriginDestination);
      }
      return segments;
    },
    []
  );

  const flattenedFlights = flightSegment.flatMap((outerArray) =>
    outerArray.flatMap((flightObject) => flightObject.Flight)
  );

  // function tryAgain() {
  //   return (
  //     <div className="flex flex-col items-center justify-center">
  //       <button
  //         onClick={() => window.location.reload()}
  //         className="mt-5 bg-main hover:bg-main-900 text-white font-bold py-2 px-4 rounded-md
  //       focus:outline-none focus:shadow-outline "
  //       >
  //         Try Again
  //       </button>
  //     </div>
  //   );
  // }

  // if (!isLoading) {
  //   if (!booking || !user) {
  //     return tryAgain();
  //   }

  //   if (
  //     !company &&
  //     (user?.role === USER_ROLE_AGENT || user?.role === USER_ROLE_MEMBER)
  //   ) {
  //     return tryAgain();
  //   }
  // }

  const handleCorporateSubmit = (selectedCorporate) => {
    setShowCorporateList(false);
    setSelectedCorporate(selectedCorporate);
  };

  if (loading) {
    return <Loading />;
  }

  function totalPrice() {
    let airPriceTotal = 0;

    if (reprice?.repriceResult?.noPriceChangeInd) {
      airPriceTotal = booking?.totalPrice;
    } else {
      airPriceTotal =
        Number(reprice?.repriceResult?.totalPrice?.totalAmount.units) +
        (booking?.serviceCharge || 0);
    }

    return convertNumbThousand(airPriceTotal) + " " + currency;
  }

  function netPrice() {
    let airPriceTotal = 0;

    if (reprice?.repriceResult?.noPriceChangeInd) {
      airPriceTotal = booking?.totalPrice;
    } else {
      airPriceTotal =
        Number(reprice?.repriceResult?.totalPrice?.totalAmount.units) +
        (booking?.serviceCharge || 0);
    }

    return airPriceTotal;
  }

  function priceToNumber(str) {
    let result = str?.toString().replace(/,/g, "");
    return +result;
  }

  function bookingTimeLimit() {
    const { OrderItems } = retrieve?.Order?.[0] || {};
    const timeLimits = OrderItems?.OrderItem?.[0]?.TimeLimits;

    if (!timeLimits) return null;

    const priceTimeLimit = timeLimits?.PriceGuaranteeTimeLimits?.Timestamp;
    const paymentTimeLimit = timeLimits?.PaymentTimeLimit?.Timestamp;

    if (!priceTimeLimit) return paymentTimeLimit;
    if (!paymentTimeLimit) return priceTimeLimit;

    return moment(priceTimeLimit).isBefore(paymentTimeLimit)
      ? priceTimeLimit
      : paymentTimeLimit;
  }

  let instructions = [];
  if (payment?.paymentInstructionsAll) {
    instructions = payment?.paymentInstructionsAll;
  } else {
    instructions = payment?.paymentInstructions;
  }

  const renderInstructions = () => {
    return (
      <div className="mt-2 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col tracking-wider">
          {instructions?.map((item, index) => (
            <div key={index}>
              <h1 className="text-xl text-center font-semibold underline">
                {item?.type}
              </h1>
              <div key={index} className="flex items-start">
                <div className="flex flex-col">
                  {item?.instructions?.map((instruction, i) => (
                    <span
                      className="text-sm font-semibold text-neutral-900"
                      key={i}
                    >
                      {instruction}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 shadow space-y-6 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <NcImage
              src={getCarrierLogo(
                flattenedFlights[0]?.MarketingCarrier?.AirlineID?.value
              )}
            />
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <span className="text-lg text-neutral-500 font-semibold">
              <h1>{flattenedFlights[0]?.MarketingCarrier?.AirlineID?.value}</h1>
            </span>
            {flattenedFlights?.map((segment, index) => (
              <div key={index}>
                <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                  <h1>
                    {segment.Departure.AirportCode.value} -{" "}
                    {segment.Arrival.AirportCode.value}
                  </h1>
                </span>
                <span className="text-base font-medium mt-1 block">
                  {moment(segment.Departure.Date).format("MMM DD YYYY")}-
                  {moment(segment.Arrival.Date).format("MMM DD YYYY")}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {segment.Departure.Time} - {segment.Arrival.Time}
                </span>
              </div>
            ))}

            <div className="w-10 border-b border-neutral-200 "></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          {/* <div>
            <PassengerPricing
              label={+adults > 0 ? "Adults" : "Labors"}
              count={adults}
              priceIndex={0}
              AirPricing={airPrice}
              rateDifference={rateDifference}
            />
            <PassengerPricing
              label="Children"
              count={children}
              priceIndex={1}
              AirPricing={airPrice}
              rateDifference={rateDifference}
            />
            <PassengerPricing
              label="Infants"
              count={infants}
              priceIndex={children > 0 ? 2 : 1}
              AirPricing={airPrice}
              rateDifference={rateDifference}
            />
          </div> */}
          <div className="flex justify-between text-neutral-600">
            <span>Service charge</span>
            <span>
              {booking?.serviceCharge} {currencyType(currency)}
            </span>
          </div>

          <div className="border-b border-neutral-200 "></div>
          {!reprice?.repriceResult?.noPriceChangeInd && (
            <div className="flex justify-between font-bold text-lg text-red-500 line-through">
              <span>previous Price</span>
              <span>
                {convertNumbThousand(booking?.totalPrice)} {currency}
              </span>
            </div>
          )}
          <div className="flex justify-between font-bold text-xl text-black">
            <span>Total Price</span>
            <span className="text-main">{totalPrice()}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 space-y-4 px-5 sm:p-6 xl:p-6 shadow">
        {/* Header Section */}
        <div className="space-y-4">
          <h2 className="text-2xl lg:text-3xl font-bold text-gray-800">
            Reprice and Confirm
          </h2>
          <div className="p-4 rounded-lg bg-gray-50 text-base">
            {reprice?.repriceResult?.noPriceChangeInd ? (
              <div className="space-y-2">
                <p className="text-green-600 flex items-center gap-2">
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Price is unchanged
                </p>
              </div>
            ) : (
              <p className="text-red-500 flex items-center gap-2">
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                Price has been updated
              </p>
            )}
            <p className="flex items-center gap-2">
              <span className="text-gray-600">Offer Expire Time</span>
              <span className="text-red-500 font-semibold">
                {bookingTimeLimit() ? (
                  moment(bookingTimeLimit()).format("MMM DD YYYY HH:mm")
                ) : (
                  <span>
                    Payment timelimit not returned from the Airline. Please
                    retrieve after some time
                  </span>
                )}
              </span>
            </p>
          </div>
          <div
            className="mt-6 border border-neutral-200 rounded-3xl flex flex-col 
          w-full sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200"
          >
            <div className="px-1 md:mx-24 md:px-5 py-4  text-lg font-light">
              {retrieve?.Passengers?.Passenger.map((travel, index) => (
                <>
                  <h1 className="text-xl font-bold p-2">
                    Passenger {index + 1}
                  </h1>
                  <div className="flex flex-row justify-between px-4 space-x-12">
                    <h1 className="">Name</h1>
                    <span className="text-neutral-500 dark:text-neutral-400 ">
                      {travel?.Name?.Given?.[0]?.value}{" "}
                      {travel?.Name?.Surname?.value}
                    </span>
                  </div>
                  <div className="flex flex-row justify-between px-4 py-2 space-x-6">
                    <h1 className="">Email</h1>
                    <h1 className="text-neutral-500 dark:text-neutral-400 ">
                      {booking?.passengers[index]?.email}{" "}
                    </h1>
                  </div>

                  <div className="flex flex-row justify-between  px-4 py-2">
                    <h1 className="">PNR</h1>
                    <span className="text-neutral-500 dark:text-neutral-400 ">
                      {retrieve.Order?.map((order) => (
                        <span key={order.OrderID?.value}>
                          {order.OrderID?.value}
                        </span>
                      ))}
                    </span>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>

        <div>
          {!payment?.paymentInstructions && (
            <PaymentMethodsList
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              setShowCorporateList={setShowCorporateList}
              user={user}
              enterprise={enterprise}
              company={booking?.company}
              totalPrice={priceToNumber(netPrice())}
              setSelectedCorporate={setSelectedCorporate}
              departureDate={flattenedFlights[0]?.Departure.Date}
              passengerTypeState={passengerTypeState}
              currency={currency}
            />
          )}
          {!DIRECT_PAYMENT.includes(paymentMethod) && payment && (
            <div className="border p-5 border-black shadow rounded-xl">
              <h1 className="text-2xl text-center font bold capitalize">
                {paymentMethod}
              </h1>
              <div className="px-2 flex justify-between flex-wrap">
                <h1 className="text-xl font-semibold">Your PNR code is:</h1>
                <button
                  className="bg-slate-300 text-white px-2 py-1 rounded-lg cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      // airReservation._attributes.LocatorCode
                      payment.paymentReference
                    );
                    toast.success("Payment Reference code copied to clipboard");
                  }}
                >
                  <h1 className="text-xl font-bold flex  ">
                    {payment.paymentReference}
                    {retrieve.Order[0].OrderID?.value}
                    <button className="mx-2 hover:text-green-800  hover:rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </button>
                  </h1>
                </button>
              </div>
              {renderInstructions()}
            </div>
          )}
        </div>

        <h1 className="text-xl font-semibold">
          Selected Payment Method:{" "}
          <span className="text-main">
            {
              paymentMethods.find((method) => method.name === paymentMethod)
                ?.title
            }
          </span>
        </h1>

        {paymentMethod === "wallet" ||
        paymentMethod === "credit" ||
        paymentMethod === "USDCredit" ? (
          <>
            <div className="lg:hidden row lg:p-0 space-y-2  ">
              <div className="col-12 ">
                <ButtonPrimary
                  onClick={() => handleClick()}
                  className="w-full mb-3"
                >
                  Confirm and pay
                </ButtonPrimary>
              </div>
            </div>

            <div className="pt-8 lg:flex justify-between hidden gap-4">
              <div className="w-full">
                <ButtonPrimary
                  onClick={() => handleClick()}
                  className="w-full 
                border-1 border-main text-black bg-slate-50 hover:text-white hover:bg-main-900
                focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 rounded-3xl
                "
                >
                  Confirm and pay
                </ButtonPrimary>
              </div>
            </div>
          </>
        ) : paymentMethod === "" ? (
          <div className="row lg:p-0 space-y-3  ">
            <div className="col-12">
              <ButtonPrimary
                className="bg-gray-400 text-white hover:bg-gray-300 w-full"
                // onclick go home
                onClick={() => navigate("/")}
              >
                No Payment Method Selected
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <div className="row lg:p-0 space-y-3  ">
            <div className="col-12">
              <ButtonPrimary
                className="bg-green-500 py-3 hover:bg-green-400 w-full"
                // onClick={initiatePayment}
              >
                Proceed
              </ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-CheckOutPage ${className} px-1 py-4`}
      data-nc-id="CheckOutPage"
    >
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className=" lg:block flex-grow mb-10">{renderSidebar()}</div>
      </main>
      {paymentMethod === "credit" && !booking?.company && (
        <CorporateList
          show={showCorporateList}
          handleClose={handleCorporateSubmit}
        />
      )}
      {paymentMethod === "kacha" && (
        <KachaCreditOTP handleSubmit={handleKachaSubmit} booking={booking} />
      )}
    </div>
  );
};

export default NDCPayLater;
