import React from "react";
import moment from "moment";

import { FaPlaneArrival, FaPlaneDeparture } from "react-icons/fa";

const RenderRoute = ({ segment, oneWayLength }) => {
  return (
    <div className="px-4 py-2 space-y-3">
      {segment.map((flight, index) => (
        <div
          key={index}
          className={`flex items-center justify-between ${
            oneWayLength - 1 === index
              ? "border-b border-gray-300 py-2 last:border-b-0"
              : "border-none"
          } `}
        >
          <div className="flex flex-col items-start">
            <div className="flex items-center">
              <FaPlaneDeparture className="mr-2 text-blue-500 w-5 h-5" />
              <span className="text-xl font-medium text-gray-900">
                {flight?.Departure?.AirportCode?.value}
              </span>
            </div>
            <span className="text-sm text-gray-600">
              {moment(flight?.Departure?.Date).format("ddd, MMM D")} at{" "}
              {flight?.Departure?.Time}
            </span>
          </div>

          <div className="flex flex-col items-center">
            <div className="flex items-center">
              <span className="mr-2 h-[2px] w-10 rounded-full bg-main-700"></span>
              <span className="text-sm text-gray-600">
                {flight?.FlightDetail?.FlightDuration?.Value}
              </span>
              <span className="ml-2 h-[2px] w-10 rounded-full bg-main-700"></span>
            </div>
          </div>

          <div className="flex flex-col items-end">
            <div className="flex items-center">
              <FaPlaneArrival className="mr-2 text-green-500 w-5 h-5" />
              <span className="text-xl font-medium text-gray-900">
                {flight?.Arrival?.AirportCode?.value}
              </span>
            </div>
            <span className="text-sm text-gray-600">
              {moment(flight?.Arrival?.Date).format("ddd, MMM D")} at{" "}
              {flight?.Arrival?.Time}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RenderRoute;
